import React, {useState} from 'react';

import {IProductWithoutDeliveryDetails} from '../../types/interfaces';

import ProductsDeliveryUnknownList from './ProductsDeliveryUnknownList';

const ProductsDeliveryUnknownSearchPage: React.FC = () => {
  const [products, setProducts] = useState<IProductWithoutDeliveryDetails[]>([]);

  return (
    <div style={{padding: '0 0em'}}>
      <ProductsDeliveryUnknownList setProducts={setProducts} openProducts={products} />
    </div>
  );
};

export default ProductsDeliveryUnknownSearchPage;

