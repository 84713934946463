import React, { FC } from "react";
import { uploadProductCsvFile } from "../../../services/apis/postApis";
import { notifyError } from "../../shared/toasts";
import FileUploadForm from "./FileUploadForm";
import handleCsvUploadResponse from "./handleCsvUploadResponse";
import { UploadFieldValues } from "./UploadMinimumStockFileInput";
import useFileUpload from "../../../hooks/useFileUpload";

interface Props {
  supplierId: string;
}

const UploadSupplierFileInput: FC<Props> = ({ supplierId }) => {
  const [postFile, loading] = useFileUpload(uploadProductCsvFile);

  const handlePostSupplierData = async (data: UploadFieldValues) => {
    if (!data.file.length) {
      return notifyError("Geen bestand geselecteerd");
    } else if (!supplierId) {
      return notifyError("Selecteer een leverancier.");
    }

    const formData = new FormData();
    formData.append("supplierGuid", supplierId);
    formData.append("files", data.file[0]);

    handleCsvUploadResponse(postFile(formData));
  };

  return (
    <FileUploadForm loading={loading} submitData={handlePostSupplierData} />
  );
};

export default UploadSupplierFileInput;
