import axios from 'axios';

const voorraadApiUri = process.env.REACT_APP_voorraad_api_url;
const SEARCH_API_URL = process.env.REACT_APP_search_api_url;

export const GetProductOverviewList = (page, pageSize, onlyVisible, onlyLocalStock) => {
  return axios.get(voorraadApiUri + `product/overview?pageSize=${pageSize}&page=${page}&onlyVisible=${!!onlyVisible}&onlyLocalStock=${!!onlyLocalStock}`).then(x => x.data);
};

export const FindProduct = (query, supplierId) => {
  return axios.get(`${SEARCH_API_URL}search?query=${encodeURIComponent(query.toString())}&locale=nl_NL${supplierId?`&supplierId=${supplierId}`:""}`);
};