import axios, { AxiosResponse } from "axios";

import {
  IPimProduct,
  IPimProductDetails,
  IProductWithoutDeliveryDetails,
  IVVPProduct,
} from "../../components/types/interfaces";

import { ISupplier, ISupplierDetails } from "./api-types/apis.types";
import { INewPurchaseOrderDetails } from "./api-types/purchase-order-details.type";
import { OpenPurchaseOrdersType } from "./api-types/open-purchase-orders.type";
import { ProductInformationOverview } from "../../components/types/product-information-overview";

const PIM_SEARCH_URL = process.env.REACT_APP_pim_search_url;
const PIM_READ_API = process.env.REACT_APP_pim_read_api_url;
const PRODUCT_CATALOG_URL = process.env.REACT_APP_product_api;
const READ_ORDER_API_URL = process.env.REACT_APP_read_order_api_url;
const SUPPLIERS_READ_API = process.env.REACT_APP_suppliers_read_api_url;

// PIM API
export const getProductsFromPIM = (
  query: string
): Promise<AxiosResponse<{ products: IPimProduct[] }>> => {
  const adjustedQuery = query ? query : "*:*";

  return axios.get(
    `${PIM_SEARCH_URL}api/search?query=${adjustedQuery}&locale=nl_NL`
  );
};

export const getProductsWithoutPriceChange = (): Promise<
  AxiosResponse<IVVPProduct[]>
> => {
  return axios.get(`${PRODUCT_CATALOG_URL}product/withoutPriceChanges`);
};

export const getProductsWithoutPriceChangeCSV = (): Promise<string> => {
  return axios
    .get(`${PRODUCT_CATALOG_URL}product/withoutPriceChanges/csv`)
    .then((response) => response.data);
};

export const getProductsWithoutDeliveryDetailsCSV = (): Promise<string> => {
  return axios
    .get(`${PRODUCT_CATALOG_URL}product/withoutDeliveryDetails/csv`)
    .then((response) => response.data);
};

export const getProductsDeliveryUnknown = (): Promise<
  AxiosResponse<{ results: IProductWithoutDeliveryDetails[] }>
> => {
  return axios.get(`${PRODUCT_CATALOG_URL}product/withoutDeliveryDetails`);
};

export const getProductDetails = (
  productId: string
): Promise<AxiosResponse<IPimProductDetails>> => {
  return axios.get(PIM_READ_API + "details?productId=" + productId);
};

export const getAllSuppliers = (): Promise<AxiosResponse<ISupplier[]>> => {
  return axios.get(
    `${SUPPLIERS_READ_API}search/suppliers?name&numOfResults=1000`
  );
};

export const getLocationStockDataCSV = (location: string): Promise<string> => {
  return axios
    .get(
      `${PRODUCT_CATALOG_URL}product/withMinimumStockUnderLocation/csv?location=Gadero&location=${location}`
    )
    .then((response) => response.data);
};

export const getProductsWithPreferredSupplierCSV = (): Promise<string> => {
  return axios
    .get(`${PRODUCT_CATALOG_URL}product/withPreferredSupplier/csv`)
    .then((response) => response.data);
};

export const getSupplierDetails = (
  id: string
): Promise<AxiosResponse<ISupplierDetails>> => {
  return axios.get(`${SUPPLIERS_READ_API}api/suppliers?supplierId=${id}`);
};

export const getProductCatalog = (supplierId: string): Promise<string> => {
  return axios
    .get(`${PRODUCT_CATALOG_URL}product/catalog?&supplierId=${supplierId}`)
    .then((response) => response.data);
};

export const getPurchaseOrder = async (
  orderId: string
): Promise<INewPurchaseOrderDetails> => {
  const response = await axios.get(
    READ_ORDER_API_URL + `purchaseOrder/` + orderId
  );

  return response.data;
};

export const getOpenPurchaseOrders = async (): Promise<
  OpenPurchaseOrdersType[]
> => {
  const response = await axios.get(READ_ORDER_API_URL + `purchaseOrder`);

  return response.data;
};

export const getProductsWithSupplier = async (
  query: string,
  supplierId: string
): Promise<ProductInformationOverview[]> => {
  const response = await axios.get<{ products: ProductInformationOverview[] }>(
    `${PIM_SEARCH_URL}api/search?query=${query}&locale=nl_NL&supplierId=${supplierId}`
  );

  return response.data.products || [];
};
