import React, {FC} from 'react';
import {IMinimumStockDetails} from '../../types/interfaces';
import {Col} from 'react-bootstrap';

interface Props {
  minimumStockDetails: IMinimumStockDetails[];
}

const MinimumStockDetails: FC<Props> = ({minimumStockDetails}) => {

  const stockGroningen = minimumStockDetails.find(stock => stock.path.length === 2 && stock.path.includes('Groningen'));
  const stockBreukelen = minimumStockDetails.find(stock => stock.path.length === 2 && stock.path.includes('Breukelen'));

  return (

    <>
      <Col>
        Groningen: {stockGroningen ? stockGroningen.minimumStock : 'geen minimale voorraad gezet'}
      </Col>
      <Col>
        Breukelen: {stockBreukelen ? stockBreukelen.minimumStock : 'geen minimale voorraad gezet'}
      </Col>
    </>
  );
};


export default MinimumStockDetails;
