import {getDeliveryAddress} from './deliveryAddresses';

export const getReceiverData = (deliveredToId) => {
  if (deliveredToId === 'BR') {
    return {
      reference: 'BR',
      contact: {
        name: 'Gadero - Breukelen',
        contactName: 'Justin Bakx',
        phone: '+31 346 700 267',
        email: 'justin@gadero.nl',
      },
      address: getDeliveryAddress(deliveredToId),
    };
  } else if (deliveredToId === 'HEERENVEEN') {
    return {
      reference: 'HEERENVEEN',
      contact: {
        name: 'Gadero - Groningen',
        contactName: 'Tom Knollema',
        phone: '+31 50 2306151',
        email: 'inkoop@gadero.nl',
      },
      address: getDeliveryAddress(deliveredToId),
    };
  } else if (deliveredToId === 'STAVANGER') {
    return {
      reference: 'STAVANGER',
      contact: {
        name: 'Gadero - Groningen',
        contactName: 'Tom Knollema',
        phone: '+31 50 2306151',
        email: 'inkoop@gadero.nl',
      },
      address: getDeliveryAddress(deliveredToId),
    };
  } else if (deliveredToId === 'STEEN') {
    return {
      reference: 'STEEN',
      contact: {
        name: 'Gadero - Groningen',
        contactName: 'Rob Klok',
        phone: '',
        email: 'sierbestrating@gadero.nl',
      },
      address: getDeliveryAddress(deliveredToId),
    };
  } else if (deliveredToId === 'DUXWOOD') {
    return {
      reference: 'DUXWOOD',
      contact: {
        name: 'Duxwood',
        contactName: 'Ernst-Jan Kardux',
        phone: '+31187497882',
        email: 'ernstjan@duxwood.nl',
      },
      address: getDeliveryAddress(deliveredToId),
    };
  } else if (deliveredToId === 'WINKEL') {
    return {
      reference: 'WINKEL',
      contact: {
        name: 'Gadero - Groningen',
        contactName: 'Tom Knollema',
        phone: '+31 50 2306151',
        email: 'inkoop@gadero.nl',
      },
      address: getDeliveryAddress(deliveredToId),
    };
  } else {
    return {
      reference: 'LDS',
      contact: {
        name: 'Gadero - Groningen',
        contactName: 'Tom Knollema',
        phone: '+31 50 2306151',
        email: 'inkoop@gadero.nl',
      },
      address: getDeliveryAddress(deliveredToId),
    };
  }
};
