export interface Address {
  street?: string;
  number?: string;
  numberAddition?: string;
  zip?: string;
  place?: string;
  country?: string;
  countryCode?: string;
  latLong?: GeoCoordinates;
}

export interface GeoCoordinates {
  latitude: number;
  longitude: number;
}

export type OrderType = typeof OrderType[keyof typeof OrderType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderType = {
  SalesOrder: "SalesOrder",
  PurchaseOrder: "PurchaseOrder",
  CustomerReturnOrder: "CustomerReturnOrder",
  LocalSalesOrder: "LocalSalesOrder",
  ServiceOrder: "ServiceOrder",
  PurchaseCommitment: "PurchaseCommitment",
  SupplierReturnOrder: "SupplierReturnOrder",
} as const;

export type FinancialParty = Party & FinancialPartyAllOf;

export interface Party {
  reference?: string;
  contact?: Contact;
  address?: Address;
}

export type FinancialPartyAllOf = {
  chamberOfCommerceNumber?: string;
  vatNumber?: string;
};

export interface Contact {
  name?: string;
  contactName?: string;
  phone?: string;
  email?: string;
}
