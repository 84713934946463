export const getDeliveryAddress = (deliveredToId) => {
  if (deliveredToId === 'BR') {
    return {
      street: 'Straatweg',
      number: '5',
      place: 'Breukelen',
      country: 'Nederland',
      countryCode: 'NL',
      zip: '3621BG',
    };
  } else if (deliveredToId === 'WINKEL') {
    return {
      street: 'Scheidersweg',
      number: '2',
      NumberAddition: 'a',
      place: 'Winkel',
      country: 'Nederland',
      countryCode: 'NL',
      zip: '1731LX',
    };
  } else if (deliveredToId === 'HEERENVEEN') {
    return {
      street: 'it Dok',
      number: '8',
      place: 'Heerenveen',
      country: 'Nederland',
      countryCode: 'NL',
      zip: '8447GL',
    };
  } else if (deliveredToId === 'STAVANGER') {
    return {
      street: 'Stavangerweg',
      number: '8',
      place: 'Groningen',
      country: 'Nederland',
      countryCode: 'NL',
      zip: '9723JC',
    };
  } else if (deliveredToId === 'STEEN') {
    return {
      street: 'Euvelgunnerweg',
      number: '19',
      zip: '9723CT',
      place: 'Groningen',
      country: 'Nederland',
      countryCode: 'NL',
    };
  } else if (deliveredToId === 'DUXWOOD') {
    return {
      street: 'Groeneweg',
      number: '12a',
      zip: '3244LJ',
      place: 'Nieuwe Tonge',
      country: 'Nederland',
      countryCode: 'NL',
    };
  } else {
    return {
      street: 'Euvelgunnerweg',
      number: '19',
      zip: '9723CT',
      place: 'Groningen',
      country: 'Nederland',
      countryCode: 'NL',
    };
  }
};
