import React, {Component} from 'react';
import {connect} from 'react-redux';

class WithAuth extends Component {
  render() {
    let oidc = this.props.oidc;
    let role = this.props.role;

    if (oidc.isLoadingUser) return null; //don't know still starting up

    if (!oidc.user || oidc.user.expired) return null; //niet ingelogd

    if (role) {
      if (!oidc.user.profile.roles || !oidc.user.profile.roles.includes(role)) {
        return null; //wel een rol vereist, maar niet aanwezig op user object
      }
    }
    return <>{this.props.children}</>;
  }
}

const mapStateToProps = (state) => ({
  oidc: state.oidc,
});

export default connect(mapStateToProps)(WithAuth);