import React from 'react';

import {Button, Container, Modal} from 'react-bootstrap';

export const ClosePurchaseOrderModal = ({handleClose, show, closeOrder}) => {

  return (
    <>
      <Modal show={show} animation={false} size='lg' centered>
        <Modal.Header>
          <Button variant='light' onClick={handleClose} className='float-right'>
            x
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Container className='text-center'>

            <p className='mb-5'>Weet je zeker dat je de order wilt sluiten?</p>

            <Button
              className='btn btn-danger'
              style={{cursor: 'pointer'}}
              onClick={() => closeOrder()}
            >Ja, Sluit Order</Button>
            <Button
              className='btn btn-light ml-2'
              style={{cursor: 'pointer'}}
              onClick={handleClose}
            >Annuleer</Button>

          </Container>

        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>
    </>
  );
};
