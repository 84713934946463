import React from "react";
import { notifyError } from "../../shared/toasts";
import handleCsvUploadResponse from "./handleCsvUploadResponse";
import FileUploadForm from "./FileUploadForm";
import { uploadPreferredSupplierCsvFile } from "../../../services/apis/postApis";
import useFileUpload from "../../../hooks/useFileUpload";
import { UploadFieldValues } from "./UploadMinimumStockFileInput";

const UploadPreferredSupplierFileInput = () => {
  const [postFile, loading] = useFileUpload(uploadPreferredSupplierCsvFile);

  const handlePostPreferredSupplierData = async (data: UploadFieldValues) => {
    if (!data.file.length) {
      return notifyError("Geen bestand geselecteerd");
    }

    const formData = new FormData();
    formData.append("file", data.file[0]);

    handleCsvUploadResponse(postFile(formData));
  };

  return (
    <FileUploadForm
      loading={loading}
      submitData={handlePostPreferredSupplierData}
    />
  );
};

export default UploadPreferredSupplierFileInput;
