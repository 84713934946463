import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CreateOrder } from "../services/ordersService";
import { FindProduct } from "../services/productCatalogService";

import "../styles/purchaseOrderDetails.scss";
import numeral from "numeral";
import "numeral/locales";
import { v4 as uuidv4 } from "uuid";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { FaEdit, FaTrash as Trash } from "react-icons/fa";

import { getBuyerData } from "../utils/buyers";
import Select from "react-select";
import { getAllSuppliers, getSupplierDetails } from "../services/apis/apis";
import { useQuery } from "@tanstack/react-query";
import { FinancialParty, OrderType } from "./types/orders";
import {
  ISupplier,
  ISupplierDetails,
} from "../services/apis/api-types/apis.types";
import { Product, SearchResponse } from "./types/defaultTypes";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { getDeliveryAddress } from "../utils/deliveryAddresses";

const possibleReceivers = [
  { value: "GRN", label: "Gadero GRN" },
  { value: "BR", label: "Gadero BR" },
  { value: "HEERENVEEN", label: "Gadero Heerenveen" },
  { value: "WINKEL", label: "Gadero - Beers Winkel" },
  { value: "DUXWOOD", label: "Duxwood" },
];

type InputProduct = Product & {
  amount?: number;
  expectedDeliveryDate?: Date;
  sku?: string;
  overridePrice?: boolean;
};

type FormSchema = {
  supplierId: string;
  sellerReference: string;
  buyerReference: string;
  expectedDeliveryDate?: Date;
  sendToSupplier: boolean;
  orderedProducts: InputProduct[];
};

const mapSupplierDetailToFinancialParty = (
  supplier: ISupplierDetails
): FinancialParty => {
  return {
    contact: { ...supplier.contact, name: supplier.name },
    address: supplier.address,
    reference: supplier.supplierId,
  };
};

const findStaffelByInputProduct = (product: InputProduct): number => {
  const { amount } = product;
  const staffels = product.supplier?.skus?.find(
    (s) => s.sku === product.sku
  )?.staffels;

  if (!staffels) return product.purchasePrice;

  if (!amount)
    return staffels.sort((a, b) => b.maximumQuantity - a.maximumQuantity)[0]
      .price;

  const staffel = staffels.find((staffel) => {
    return (
      amount >= staffel.minimumQuantity && amount < staffel.maximumQuantity
    );
  });

  if (!staffel) return product.purchasePrice;

  return staffel.price;
};

const findStaffelByInputProductSingle = (product: InputProduct): number => {
  const { amount } = product;
  const staffels = product.supplier?.skus?.find(
    (s) => s.sku === product.sku
  )?.staffels;
  const factor = product.supplier?.skus?.find(
    (s) => s.sku === product.sku
  )?.factor;

  if (!staffels) return product.purchasePrice;

  if (!amount)
    return staffels.sort((a, b) => b.maximumQuantity - a.maximumQuantity)[0]
      .price;

  const staffel = staffels.find((staffel) => {
    return (
      amount >= staffel.minimumQuantity && amount < staffel.maximumQuantity
    );
  });

  if (!staffel) return product.purchasePrice;

  const numberFromFactor = 1 / (factor ? factor : 1);

  return staffel.price / numberFromFactor;
};

const findStaffelAmountByProduct = (product: InputProduct): number => {
  const { amount } = product;
  const staffels = product.supplier?.skus?.find(
    (s) => s.sku === product.sku
  )?.staffels;
  const factor = product.supplier?.skus?.find(
    (s) => s.sku === product.sku
  )?.factor;

  if (!staffels) return amount ? amount : 1;

  if (!amount)
    return staffels.sort((a, b) => b.maximumQuantity - a.maximumQuantity)[0]
      .price;

  return amount / (factor ? factor : 1);
};

const findSingleStaffelAmountByProduct = (product: InputProduct): number => {
  const amount = 1;
  const factor = product.supplier?.skus?.find(
    (s) => s.sku === product.sku
  )?.factor;

  return amount / (factor ? factor : 1);
};

const findStaffelByMinimumQuantity = (product: InputProduct): number => {
  const staffels = product.supplier?.skus?.find(
    (s) => s.sku === product.sku
  )?.staffels;

  const staffel = staffels?.find((s) => s.minimumQuantity + s.maximumQuantity);

  return staffel ? staffel?.minimumQuantity : 1;
};

const findStaffelByMaximumQuantity = (product: InputProduct): number => {
  const staffels = product.supplier?.skus?.find(
    (s) => s.sku === product.sku
  )?.staffels;

  const staffel = staffels?.find((s) => s.minimumQuantity + s.maximumQuantity);

  return staffel ? staffel?.maximumQuantity : 1;
};

const calculateTotalPriceByInputProducts = (
  orderedProducts: InputProduct[]
): string => {
  const result = orderedProducts.reduce(
    (acc, p) => acc + (p.amount || 0) * findStaffelByInputProduct(p),
    0
  );
  return numeral(result).format("$ 0.00");
};

const PIM_PRODUCT_URL = process.env.REACT_APP_pim_url;

const CreatePurchaseOrder = () => {
  const { data } = useQuery(["suppliers"], () => getAllSuppliers());
  const suppliers = data?.data;
  const [supplier, setSupplier] = useState<ISupplier>();
  const [query, setQuery] = useState("");

  const { data: searchResponse } = useQuery<SearchResponse>(
    ["products", query, supplier?.supplierId],
    () =>
      FindProduct(query, supplier?.supplierId).then((response) => response.data)
  );

  const [publishedVisible, setPublishedVisible] = useState(true);

  const { register, handleSubmit, control, watch } = useForm<FormSchema>();

  const { fields, append, remove, update } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "orderedProducts", // unique name for your Field Array
  });

  const orderedProducts = watch("orderedProducts") || [];

  const isValidDate = (d?: Date) => {
    return d instanceof Date && isFinite(d.getTime());
  };

  const addToOrder = (product: Product) => {
    append({
      ...product,
      purchasePrice: findStaffelByInputProduct(product),
      amount: 1,
      overridePrice: false,
    });
  };

  const onSubmit = async (data: FormSchema) => {
    if (!supplier) throw new Error("supplier not found");

    const { data: supplierDetails } = await getSupplierDetails(
      supplier.supplierId
    );

    if (!supplierDetails) throw new Error("supplier not found");

    const orderToCreate = {
      id: uuidv4(),
      buyerReference: moment.utc().format("[MPO-]YYMMDD-HHmmss"),
      sellerReference: data.sellerReference,
      shipping: {
        shippingMethod: 1,
        shippingCost: {
          currency: "€",
          value: 0,
        },
        deliveryAddress: getDeliveryAddress(),
      },
      orderType: OrderType.PurchaseOrder,
      buyer: getBuyerData(data?.buyerReference),
      seller: mapSupplierDetailToFinancialParty(supplierDetails),
      receiver: getBuyerData(data?.buyerReference),
      goodsItems: data.orderedProducts.map((product) => ({
        orderlineId: uuidv4(),
        price: {
          currency: "€",
          value: product.overridePrice
            ? product.purchasePrice
            : findStaffelByInputProduct(product),
          vatPercentage: 21,
          valueIncludesVat: false,
          vatExempted: false,
        },
        quantity: {
          unit: "stuks",
          value: product.amount,
        },
        item: {
          id: product.productId,
          name: product.name,
          productNumber: product.sku,
          itemConversionFactor:
            product.supplier.skus.find((sku) => sku.sku === product.sku)
              ?.itemConversionFactor ?? 1,
        },
        ...(isValidDate(product.expectedDeliveryDate) && {
          productExpectations: [
            {
              productId: product.productId,
              productAmount: {
                unit: "stuks",
                value: product.amount,
              },
              expectedDeliveryDate: product.expectedDeliveryDate || new Date(),
            },
          ],
        }),
      })),
    };

    const createOrder = {
      order: orderToCreate,
      sentToSupplier: data.sendToSupplier,
    };

    CreateOrder(createOrder).then((response) => {
      if (response.success === true) {
        toast.success("Product met succes verstuurd", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  numeral.locale("nl-nl");

  return (
    <div className="purchaseOrderDetails">
      <Link to={`/`} className="backLink">
        <button style={{ cursor: "pointer" }}>Terug</button>
      </Link>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h4>Inkooporder aanmaken</h4>
        <div className="parties mt-2">
          <div className="party">
            <h5>Verkoper</h5>
            <Select
              isDisabled={orderedProducts?.length > 0}
              isSearchable
              placeholder={"Selecteer een verkoper"}
              options={suppliers?.sort((a, b) => a.name.localeCompare(b.name))}
              getOptionLabel={(supplier) => supplier.name}
              onChange={(e) => setSupplier(e as ISupplier)}
            />
            <br />
            Extern referentienummer:
            <input
              className="mt-1 ml-1"
              type={"text"}
              {...register("sellerReference", { required: true })}
            />
          </div>
          <br />
          <hr />
          <div className="party">
            <h5>Ontvanger</h5>
            <Controller
              name="buyerReference"
              control={control}
              render={({ field }) => (
                <Select
                  name={field.name}
                  ref={field.ref}
                  isSearchable
                  placeholder={"Selecteer een ontvanger"}
                  onChange={(value) => field.onChange(value?.value)}
                  options={possibleReceivers}
                />
              )}
            />
          </div>
          <div className="party">
            <h5>Verwachte leverdatum</h5>
            <input
              type="date"
              {...register("expectedDeliveryDate", {
                required: true,
                valueAsDate: true,
              })}
            />
          </div>
        </div>
        <br />
        <hr />
        <label className="alreadySentLabel">
          <input type="checkbox" {...register("sendToSupplier")} /> Reeds
          verzonden naar leverancier
        </label>
        <h5>Besteld</h5>
        <table className="orderProductsTable">
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>Product</th>
              <th>SKU</th>
              <th className="amount">Besteld</th>
              <th className="amount">Aantal</th>
              <th className="expectedDeliveryDate">Verwacht op</th>
              <th className="money"> Prijs ex. BTW</th>
              <th className="money">Totaal</th>
            </tr>
          </thead>
          <tbody>
            {fields.map((product, index) => (
              <tr
                key={product.productId}
                className={`${product.purchasePrice > 0 ? "withError" : ""}`}
              >
                <td>
                  <Trash className="removeIcon" onClick={() => remove(index)} />
                </td>
                <td>{product.name}</td>
                <td>
                  {product?.supplier?.skus && (
                    <Controller
                      name={`orderedProducts.${index}.sku`}
                      control={control}
                      render={({ field }) => (
                        <Select
                          isClearable={true}
                          name={field.name}
                          ref={field.ref}
                          onChange={(value) => field.onChange(value?.value)}
                          placeholder={"Selecteer een SKU"}
                          options={product.supplier.skus.map((sku) => ({
                            label: `1 x" ${sku.sku}`,
                            value: sku.sku,
                          }))}
                        />
                      )}
                    />
                  )}
                </td>
                <td className="amount">
                  <input
                    className="intInput"
                    type="number"
                    inputMode={"numeric"}
                    {...register(`orderedProducts.${index}.amount`, {
                      required: true,
                    })}
                  />
                </td>

                {product.supplier?.skus ? (
                  <td
                    title={`Min QTY: ${findStaffelByMinimumQuantity(
                      orderedProducts[index]
                    ).toString()} , Max QTY: ${findStaffelByMaximumQuantity(
                      orderedProducts[index]
                    )}`}
                  >
                    x {findSingleStaffelAmountByProduct(orderedProducts[index])}{" "}
                    = {findStaffelAmountByProduct(orderedProducts[index])}
                  </td>
                ) : (
                  <td>{findStaffelAmountByProduct(orderedProducts[index])}</td>
                )}

                <td>
                  <input
                    type={"date"}
                    {...register(
                      `orderedProducts.${index}.expectedDeliveryDate`,
                      {
                        required: true,
                        valueAsDate: true,
                      }
                    )}
                  />
                </td>
                <td className="money">
                  {product.overridePrice && (
                    <input
                      step="0.01"
                      type={"number"}
                      {...register(`orderedProducts.${index}.purchasePrice`, {
                        required: false,
                      })}
                    />
                  )}
                  {!product.overridePrice && (
                    <span>
                      <button
                        style={{ padding: "10px" }}
                        type={"button"}
                        onClick={() =>
                          update(index, {
                            ...product,
                            overridePrice: true,
                          })
                        }
                      >
                        <FaEdit />
                      </button>
                      &nbsp;&nbsp;
                      {numeral(
                        findStaffelByInputProductSingle(orderedProducts[index])
                      ).format("$0.00")}
                    </span>
                  )}
                </td>
                <td className="money">
                  {numeral(
                    findStaffelByInputProduct(orderedProducts[index]) *
                      (orderedProducts[index].amount || 0)
                  ).format("$0.00")}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={6}>{""}</td>
              <td className="total money">
                {calculateTotalPriceByInputProducts(orderedProducts)}
              </td>
            </tr>
          </tfoot>
        </table>
        <div className="actionBar">
          <button
            disabled={orderedProducts.length < 1}
            style={{ cursor: "pointer" }}
          >
            Opslaan
          </button>
        </div>
      </form>

      <div className="productFinder">
        <h5>Product toevoegen</h5>
        <label>
          <span>Zoek op Naam / Artikelnummer: </span>
          <input
            disabled={!supplier}
            title={!supplier ? "selecteer eerst een supplier" : undefined}
            type="text"
            onChange={(e) => setQuery(e.target.value)}
            value={query}
          />
        </label>
        <br />
        <br />
        <button className="mb-3" onClick={() => setPublishedVisible((t) => !t)}>
          {publishedVisible ? "Toon" : "Verberg"} niet gepubliseerde producten
        </button>

        {searchResponse?.products?.length && (
          <table>
            <thead>
              <tr>
                <th>Naam</th>
                <th className="center">CostPrice</th>
                <th className="center">Ean</th>
                <th> SKU's</th>
                <th> Link naar PIM</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {searchResponse?.products?.map((foundProduct, idx) => (
                <tr
                  key={foundProduct.productId}
                  style={{ borderBottom: "2px solid #ddd" }}
                >
                  <td>
                    <b>{idx + 1}.</b> {foundProduct.name}
                  </td>
                  <td className="center">
                    {numeral(foundProduct.purchasePrice).format("$ 0.00")}
                  </td>
                  <td className="center">{foundProduct.articleNumbers}</td>
                  <td>
                    <ul>
                      {foundProduct.supplier?.skus?.map((sku) => (
                        <li key={idx}>
                          {"1 x "} {sku.sku}
                        </li>
                      ))}
                    </ul>
                  </td>
                  <td>
                    <a
                      href={`${PIM_PRODUCT_URL}edit/product/${foundProduct.productId}/`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Open in PIM
                    </a>
                  </td>
                  <td>
                    {foundProduct.supplier?.skus.length > 0 && (
                      <button onClick={() => addToOrder(foundProduct)}>
                        toevoegen
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={6} className="center">
                  <button onClick={() => null}>Meer producten tonen</button>
                </td>
              </tr>
            </tfoot>
          </table>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default CreatePurchaseOrder;
