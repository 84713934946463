import React, { FC } from "react";
import { Navbar } from "react-bootstrap";
import "./Navigation.css";
import { Link } from "react-router-dom";

const Navigation: FC = () => {
  return (
    <Navbar collapseOnSelect variant={"dark"} expand="lg">
      <Navbar.Brand as={Link} to="/" style={{ textDecoration: "none"}}>
        <img className="mx-2" alt="logo" src="/img/egel_gadero_wit.png" />
        Inkoop app
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
    </Navbar>
  );
};

export default Navigation;
