import React from "react";
import moment from "moment";
import { ReceivedGoodsItemSchemaType } from "./form-schemas";

type Props = {
  receivedGoodsItems: ReceivedGoodsItemSchemaType[];
};

const ReceivedGoodsOverview = ({ receivedGoodsItems }: Props) => {
  return (
    <tr>
      <td className={"p-0 px-5 bg-secondary bg-opacity-25"} colSpan={100}>
        <table>
          <thead>
            <tr>
              <th>Ingecheckt</th>
              <th>User</th>
              <th>Pakbon</th>
              <th>Aantal</th>
              <th>Locatie</th>
            </tr>
          </thead>
          <tbody>
            {receivedGoodsItems?.map((goodsItem, index) => {
              return (
                <tr key={index}>
                  <td>
                    {moment(goodsItem.processedEventDate).format(
                      "YYYY-MM-DD - HH:MM"
                    )}
                  </td>
                  <td>{goodsItem.userName ?? "onbekend"}</td>
                  <td>{goodsItem.reference}</td>
                  <td>{goodsItem.quantity}</td>
                  <td>
                    {goodsItem.location[0]} - {goodsItem.location[1]}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </td>
    </tr>
  );
};

export default ReceivedGoodsOverview;
