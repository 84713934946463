import React, {useEffect} from 'react';

import {IProductWithoutDeliveryDetails} from '../../types/interfaces';
import {getProductsDeliveryUnknown} from '../../../services/apis/apis';

interface Props {
  openProducts: IProductWithoutDeliveryDetails[];
  setProducts: (products: IProductWithoutDeliveryDetails[]) => void;
}

const PIM_PRODUCT_URL = process.env.REACT_APP_pim_url;

const ProductsDeliveryUnknownList: React.FC<Props> = ({setProducts, openProducts}) => {

  useEffect(() => {
    const handleQuery = async () => {
      const {data} = await getProductsDeliveryUnknown();
      setProducts(data.results);
    };

    handleQuery();
  }, [setProducts]);

  return (
    <table className='table table-striped table-sm'>
      <thead>
      <tr>
        <th scope='col'>Naam</th>
        <th scope='col' style={{textAlign: 'right'}}>Link</th>
      </tr>
      </thead>
      <tbody>
      {openProducts?.map((product) => (
        <tr key={product.id}>
          <td style={{borderTop: 'none'}}>{product.name}</td>
          <td className='d-flex justify-content-end' style={{borderTop: 'none'}}>
            <a href={`${PIM_PRODUCT_URL}edit/product/${product.id}/`} target='_blank' rel='noopener noreferrer'>
              <button className='btn btn-sm btn-success btn-responsive' style={{cursor: 'pointer'}}>Open in PIM</button>
            </a>
          </td>
        </tr>
      ))}
      </tbody>
    </table>
  );
};

export default ProductsDeliveryUnknownList;
