import { notifyError, notifySuccess } from "../../shared/toasts";

const handleCsvUploadResponse = (data: unknown) => {
  if (data) {
    notifySuccess("Bestand succesvol toegevoegd");
  } else {
    notifyError(
      "Er is iets misgegaan met toevoegen van het bestand. Probeer het nog een keer."
    );
  }
};

export default handleCsvUploadResponse;
