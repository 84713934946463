import React, { FC, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { IPimProductDetails, ProductTypes } from "../../types/interfaces";
import { getProductDetails } from "../../../services/apis/apis";
import ProductCard from "./ProductCard";
import LoadingView from "../../shared/LoadingView";

const ProductDetails: FC = () => {
  const [productDetails, setProductDetails] = useState<IPimProductDetails>(
    {} as IPimProductDetails
  );
  const [loading, setLoading] = useState(true);
  const { productId } = useParams<ProductTypes>();
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    const fetchProductDetails = async () => {
      const { data } = await getProductDetails(productId);
      setProductDetails(data);
      setLoading(false);
    };
    fetchProductDetails();
  }, [productId, refresh]);

  const refreshProductDetails = () => {
    setRefresh(!refresh);
  };

  if (loading) {
    return <LoadingView loading={loading} inline={false} />;
  } else if (Object.keys(productDetails).length) {
    return (
      <>
        <h4 className="font-weight-light text-center mt-4 mb-4">
          Minimale voorraad wijzigen
        </h4>
        <ProductCard
          productId={productId}
          productDetails={productDetails}
          refreshProductDetails={refreshProductDetails}
        />
        <div className="d-flex justify-content-center mt-5">
          <Link to={"/"}>
            <button className="btn btn-primary">
              Terug naar Inkoopportaal
            </button>
          </Link>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="d-flex justify-content-center mt-5">
          <h6 className="text-center mt-3">Geen productdetails beschikbaar.</h6>
        </div>
        <div className="d-flex justify-content-center mt-5">
          <Link to={"/"}>
            <button className="btn btn-primary">
              Terug naar Inkoopportaal
            </button>
          </Link>
        </div>
      </>
    );
  }
};

export default ProductDetails;
