import React, {useEffect, useState} from 'react';

import {IVVPProduct} from '../../types/interfaces';
import {getProductsWithoutPriceChange} from '../../../services/apis/apis';
import LoadingSpinner from "../../shared/loading-spinner";

interface Props {
    product: IVVPProduct[];
    setProducts: (products: IVVPProduct[]) => void;
}

const PIM_PRODUCT_URL = process.env.REACT_APP_pim_url;

const ProductsWithoutVVPList: React.FC<Props> = ({setProducts, product = []}) => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const handleQuery = async () => {
            const {data} = await getProductsWithoutPriceChange();
            setLoading(true);
            setProducts(data);
            setLoading(false);
        };
        handleQuery();
    }, [setProducts]);

    if (loading) {
        return <LoadingSpinner />
    }

    return (
        <table className='table table-striped table-sm'>
            <thead>
            <tr>
                <th scope='col'>Naam</th>
                <th scope='col' style={{textAlign: 'right'}}>Link</th>
            </tr>
            </thead>
            <tbody>
            {product.map((product) => (
                <tr key={product.id}>
                    <td style={{borderTop: 'none'}}>{product.name}</td>

                    <td className='d-flex justify-content-end' style={{borderTop: 'none'}}>
                        <a href={`${PIM_PRODUCT_URL}edit/product/${product.id}`} target='_blank'
                           rel='noopener noreferrer'>
                            <button className='btn btn-sm btn-success btn-responsive' style={{cursor: 'pointer'}}>Open
                                in PIM
                            </button>
                        </a>
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
    );
};

export default ProductsWithoutVVPList;
