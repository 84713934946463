import { Button, Form, Table } from "react-bootstrap";
import React, { FC } from "react";
import styled from "styled-components";

const Skeleton = styled.div`
  animation: skeleton-loading 1s linear infinite alternate;
  padding: 8px;
  width: 160px;
  border-radius: 10px;
  margin-top: 5px;

  @keyframes skeleton-loading {
    0% {
      background-color: hsl(200, 20%, 90%);
      width: 15%;
    }
    100% {
      background-color: hsl(200, 20%, 95%);
      width: 55%;
    }
`;

const myArray = [] as number[];

for (let i = 0; i < 15; i++) {
  myArray.push(i);
}

const myRow = (x: number) => {
  return (
    <tr key={x}>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Button
          size="sm"
          className="btn btn-light btn-sm float-right"
          style={{ color: "lightgray" }}
        >
          OPEN
        </Button>
      </td>
    </tr>
  );
};

const LoadingTable: FC = () => {
  return (
    <>
      <div className="search_and_select">
        <Form.Control className="searchBar" type="email" placeholder="..." />
      </div>
      <Table striped bordered hover className="mt-2 mb-5">
        <thead>
          <tr>
            <th style={{ width: "180px" }}>Verkoper</th>
            <th>Aan</th>
            <th>Besteldatum</th>
            <th>Verwacht op</th>
            <th>Inkoop Ref.</th>
            <th className="text-right">Ingecheckt</th>
            <th>{}</th>
          </tr>
        </thead>
        <tbody>{myArray.map((i) => myRow(i))}</tbody>
      </Table>
    </>
  );
};

export default LoadingTable;
