import React, { useMemo, useState } from "react";
import { Form, Table } from "react-bootstrap";
import { getOpenPurchaseOrders } from "../../../services/apis/apis";
import moment from "moment";
import LoadingTable from "./LoadingTable";
import OpenPurchaseOrderTableRow from "./OpenPurchaseOrderTableRow";
import { useQuery } from "@tanstack/react-query";

const OpenPurchaseOrdersList = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [reversed, setReversed] = useState(false);

  const { data, isLoading } = useQuery(["open-purchase-orders"], () =>
    getOpenPurchaseOrders()
  );

  const filteredPurchaseOrders = useMemo(() => {
    return (
      data
        ?.sort((a, b) =>
          reversed
            ? moment(a.initialOrderDate).diff(b.initialOrderDate)
            : moment(b.initialOrderDate).diff(a.initialOrderDate)
        )
        .filter((filteredPurchaseOrders) => {
          return (
            searchTerm === "" ||
            filteredPurchaseOrders?.seller?.contact?.name
              ?.toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            filteredPurchaseOrders.buyerReference
              ?.toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            filteredPurchaseOrders.initialOrderDate
              ?.toString()
              ?.toLowerCase()
              .includes(searchTerm.toLowerCase())
          );
        }) || []
    );
  }, [data, searchTerm, reversed]);

  if (isLoading) {
    return <LoadingTable />;
  } else if (data) {
    return (
      <>
        <div className="search_and_select">
          <Form.Control
            className="searchBar"
            placeholder="Zoeken..."
            onChange={(event) => {
              setSearchTerm(event.target.value);
            }}
          />
        </div>

        <Table striped bordered hover className="mt-2 mb-5">
          <thead>
            <tr>
              <th style={{ width: "180px" }}>Verkoper</th>
              <th>Aan</th>
              <th
                style={{ cursor: "pointer" }}
                onClick={() => setReversed(!reversed)}
              >
                <span>
                  Besteldatum
                  {reversed ? (
                    <i className="fas fa-chevron-up float-right mt-1"></i>
                  ) : (
                    <i className="fas fa-chevron-down float-right mt-1"></i>
                  )}
                </span>
              </th>
              <th>Verwacht op</th>
              <th>Inkoop Ref.</th>
              <th className="text-right">Ingecheckt</th>
              <th>{}</th>
            </tr>
          </thead>
          <tbody>
            {filteredPurchaseOrders?.map((openPurchaseOrder) => {
              return (
                <OpenPurchaseOrderTableRow
                  key={openPurchaseOrder.id}
                  openPurchaseOrder={openPurchaseOrder}
                />
              );
            })}
          </tbody>
        </Table>
      </>
    );
  } else {
    return <p>Geen data kunnen vinden</p>;
  }
};

export default OpenPurchaseOrdersList;
