import React, { Suspense, lazy, useState, ReactNode } from "react";
import { Link } from "react-router-dom";
import "../styles/purchaseorderoverview.scss";
import WithAuth from "./auth/withAuth";
import LoadingSpinner from "./shared/loading-spinner";
import CSVUpload from "./home-page-tabs/csvUpload/CSVUpload";
import ProductsVVPSearchPage from "./home-page-tabs/ProductsVVP/ProductsVVPSearchPage";
import ProductsDeliveryUnknownSearchPage from "./home-page-tabs/ProductsDeliveryUnknown/ProductsDeliveryUnknownSearchPage";
import CSVDownload from "./home-page-tabs/csvDownload/CSVDownload";
import OpenPurchaseOrdersList from "./home-page-tabs/openPurchaseOrders/open-purchase-orders-list";

const AutomatedOrdersList = lazy(
  () => import("./home-page-tabs/automated-orders-list")
);
const ProductSearchPage = lazy(
  () => import("./home-page-tabs/minimumStock/ProductSearchPage")
);

const Home = ({ selectedTabInit = "automated-orders" }) => {
  const [selectedTab, setSelectedTab] = useState(selectedTabInit);

  return (
    <>
      <WithAuth role="Inkoper">
        <Link to={`/create/`} className="createNewLink">
          <button style={{ cursor: "pointer" }}>
            Maak nieuwe inkoop-order
          </button>
        </Link>
      </WithAuth>
      <h2>Inkoopportaal</h2>
      <div className="tabs">
        <hr />

        <NavTab
          name="automated-orders"
          selected={selectedTab === "automated-orders"}
          label="Inkoop voorstel"
          onClick={() => setSelectedTab("automated-orders")}
        >
          <Suspense key={"automated-orders"} fallback={<LoadingSpinner />}>
            <AutomatedOrdersList />
          </Suspense>
        </NavTab>

        <NavTab
          name="purchase-orders"
          selected={selectedTab === "open-purchase-orders"}
          label="Open Inkoopopdrachten"
          onClick={() => setSelectedTab("open-purchase-orders")}
        >
          <Suspense key={"purchase-orders"} fallback={<LoadingSpinner />}>
            <OpenPurchaseOrdersList />
          </Suspense>
        </NavTab>

        <NavTab
          name="productsVVP"
          selected={selectedTab === "productsVVP"}
          label="Producten zonder VVP"
          onClick={() => setSelectedTab("productsVVP")}
        >
          <Suspense key={"productsVVP"} fallback={<LoadingSpinner />}>
            <ProductsVVPSearchPage />
          </Suspense>
        </NavTab>

        <NavTab
          name="deliveryUnknown"
          selected={selectedTab === "deliveryUnknown"}
          label="Producten Inkoop Onbekend"
          onClick={() => setSelectedTab("deliveryUnknown")}
        >
          <Suspense key={"deliveryUnknown"} fallback={<LoadingSpinner />}>
            <ProductsDeliveryUnknownSearchPage />
          </Suspense>
        </NavTab>

        <NavTab
          name="set-minimumstock"
          selected={selectedTab === "set-minimumstock"}
          label="Minimale voorraad"
          onClick={() => setSelectedTab("set-minimumstock")}
        >
          <Suspense key={"set-minimumstock-tab"} fallback={<LoadingSpinner />}>
            <ProductSearchPage />
          </Suspense>
        </NavTab>

        <NavTab
          name="csv-upload"
          selected={selectedTab === "csv-upload"}
          label="CSV Upload"
          onClick={() => setSelectedTab("csv-upload")}
        >
          <Suspense key={"csv-upload-tab"} fallback={<LoadingSpinner />}>
            <CSVUpload />
          </Suspense>
        </NavTab>

        <NavTab
          name="csv-download"
          selected={selectedTab === "csv-download"}
          label="CSV Download"
          onClick={() => setSelectedTab("csv-download")}
        >
          <Suspense key={"csv-download-tab"} fallback={<LoadingSpinner />}>
            <CSVDownload />
          </Suspense>
        </NavTab>
      </div>
    </>
  );
};

type NavTabProps = {
  children: ReactNode;
  name: string;
  selected: boolean;
  label: string;
  onClick: () => void;
};

const NavTab = ({ children, name, selected, label, onClick }: NavTabProps) => {
  return (
    <>
      <input
        className="tab-switch"
        name={name}
        type="radio"
        id={name}
        onChange={onClick}
        checked={selected}
        style={{ width: "100px" }}
      />
      <label htmlFor={name}>{label}</label>
      <div className="tab-content">{selected && children}</div>
    </>
  );
};

export default Home;
