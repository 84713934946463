import {FinancialParty} from "../components/types/orders";

export const getBuyerData = (buyerReference?: string): FinancialParty => {

    console.log('buyerReference: ', buyerReference)

    if (!buyerReference) {
        throw new Error('Geen verkoper referentie opgegeven')
    }

    switch (buyerReference) {
        case 'BR':
            return {
                reference: 'BR',
                contact: {
                    name: 'Gadero - Groningen',
                    contactName: 'Tom Knollema',
                    phone: '+31 50 2306151',
                    email: 'inkoop@gadero.nl',
                },
                address: {
                    street: 'Straatweg',
                    number: '5',
                    place: 'Breukelen',
                    country: 'Nederland',
                    countryCode: 'NL',
                    zip: '3621BG',
                },
                chamberOfCommerceNumber: '50837702',
                vatNumber: 'NL 8229 56 706 B01',
            }
        case 'WINKEL':
            return {
                reference: 'WINKEL',
                contact: {
                    name: 'Gadero - Groningen',
                    contactName: 'Tom Knollema',
                    phone: '+31 50 2306151',
                    email: 'inkoop@gadero.nl',
                },
                address: {
                    street: 'Scheidersweg',
                    number: '2',
                    numberAddition: 'a',
                    place: 'Winkel',
                    country: 'Nederland',
                    countryCode: 'NL',
                    zip: '1731LX',
                },
                chamberOfCommerceNumber: '50837702',
                vatNumber: 'NL 8229 56 706 B01',
            };
        case 'HEERENVEEN':
            return {
                reference: 'HEERENVEEN',
                contact: {
                    name: 'Gadero - Groningen',
                    contactName: 'Tom Knollema',
                    phone: '+31 50 2306151',
                    email: 'inkoop@gadero.nl',
                },
                address: {
                    street: 'it Dok',
                    number: '8',
                    place: 'Heerenveen',
                    country: 'Nederland',
                    countryCode: 'NL',
                    zip: '8447GL',
                },
                chamberOfCommerceNumber: '50837702',
                vatNumber: 'NL 8229 56 706 B01',
            };
        case 'DUXWOOD':
            return {
                reference: 'DUXWOOD',
                contact: {
                    name: 'Gadero - Groningen',
                    contactName: 'Tom Knollema',
                    phone: '+31 50 2306151',
                    email: 'inkoop@gadero.nl',
                },
                address: {
                    street: 'Groeneweg',
                    number: '12a',
                    zip: '3244LJ',
                    place: 'Nieuwe Tonge',
                    country: 'Nederland',
                    countryCode: 'NL',
                },
                chamberOfCommerceNumber: '50837702',
                vatNumber: 'NL 8229 56 706 B01',
            };

        default:
            return {
                reference: 'LDS',
                contact: {
                    name: 'Gadero - Groningen',
                    contactName: 'Tom Knollema',
                    phone: '+31 50 2306151',
                    email: 'inkoop@gadero.nl',
                },
                address: {
                    street: 'Euvelgunnerweg',
                    number: '19',
                    zip: '9723CT',
                    place: 'Groningen',
                    country: 'Nederland',
                    countryCode: 'NL',
                },
                chamberOfCommerceNumber: '50837702',
                vatNumber: 'NL 8229 56 706 B01',
            };
    }
}
