import React from "react";
import "../../styles/loadingSpinner.scss";

const LoadingSpinner = () => {
  return (
    <div className="large progress">
      <div>Loading…</div>
    </div>
  );
};

export default LoadingSpinner;
