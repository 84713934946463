import React, {useCallback, useState} from 'react';

import {IVVPProduct} from '../../types/interfaces';
import ProductsWithoutVVPList from './ProductsWithoutVVPList';

const ProductsVVPSearchPage: React.FC = () => {
  const [products, setProducts] = useState<IVVPProduct[]>([]);

  const handleSearchResults = useCallback((results: IVVPProduct[]) => {
    setProducts(results);
  }, []);

  return (
    <div style={{padding: '0 0em'}}>
      <ProductsWithoutVVPList setProducts={handleSearchResults} product={products} />
    </div>
  );
};

export default ProductsVVPSearchPage;

