import React, { FC, useState } from "react";
import { Card, Col, Row, Form, Button } from "react-bootstrap";
import Select from "react-select";

import locationOptions from "../../../config/options/locations";
import { notifyError, notifySuccess, notifyWarning } from "../../shared/toasts";
import { postMinimumStock } from "../../../services/apis/postApis";
import { AxiosResponse } from "axios";
import { IPimProductDetails } from "../../types/interfaces";
import MinimumStockDetails from "./MinimumStockDetails";

interface Props {
  productId: string;
  productDetails: IPimProductDetails;
  refreshProductDetails: () => void;
}

const ProductCard: FC<Props> = ({
  productId,
  productDetails,
  refreshProductDetails,
}) => {
  const [minimumStock, setMinimumStock] = useState(0);
  const [location, setLocation] = useState("");

  const setMinimumStockPostObject = () => {
    return {
      productId: productId,
      minimumStock: minimumStock,
      Path: ["Gadero", location],
      eTag: new Date().toJSON(),
    };
  };

  const handleResponse = (response: AxiosResponse) => {
    if (response.data.success) {
      notifySuccess("Minimale voorraad met succes opgeslagen.");
      refreshProductDetails();
    } else {
      notifyError(
        "Er is iets misgegaan, neem bij herhaling contact op met IT."
      );
    }
  };

  const handleSubmitMinimumStock = async () => {
    if (minimumStock < 0) {
      return notifyWarning("Het aantal moet een positief getal zijn");
    }
    if (!location) {
      return notifyWarning("Kies eerst een locatie.");
    }

    const postObject = setMinimumStockPostObject();

    try {
      const response = await postMinimumStock(postObject);
      handleResponse(response);
    } catch (error) {
      notifyError(
        "Er is iets misgegaan, neem bij herhaling contact op met IT."
      );
    }
  };

  return (
    <Card>
      <Card.Header>Productinformatie</Card.Header>
      <Card.Body>
        <Row className="mb-1 mt-2">
          <Col className="col-md-2">Naam:</Col>
          <Col className="col-md-10">{productDetails.locales.nl_NL.name}</Col>
        </Row>
        <Row className="mb-1">
          <Col className="col-md-2">ID:</Col>
          <Col className="col-md-10">{productId}</Col>
        </Row>
        <Row className=" mt-3 mb-5">
          <Col className="col-md-2">Huidige minimale voorraad:</Col>
          <MinimumStockDetails
            minimumStockDetails={productDetails.minimumStock}
          />
        </Row>
        <Row className="mb-2">
          <Col className="col-md-2">
            <h6 className="mt-1">Locatie:</h6>
          </Col>
          <Col className="col-md-10">
            <Select
              placeholder="Selecteer een locatie"
              options={locationOptions}
              onChange={(value) => setLocation(value ? value.value : "")}
            />
          </Col>
        </Row>
        <Row className="mt-1">
          <Col className="col-md-2">
            <h6 className="mt-1">Minimale voorraad:</h6>
          </Col>
          <Col className="col-md-10">
            <Form.Control
              type="number"
              value={minimumStock}
              required
              onChange={(e) => setMinimumStock(parseInt(e.target.value))}
              placeholder="Geef minimale voorraad op"
            />
          </Col>
        </Row>
        <Button
          className="mt-3 float-right"
          variant="success"
          onClick={handleSubmitMinimumStock}
        >
          Opslaan
        </Button>
      </Card.Body>
    </Card>
  );
};

export default ProductCard;
