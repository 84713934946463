import React, { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getPurchaseOrder } from "../../services/apis/apis";
import { Link, useParams } from "react-router-dom";
import { ParamTypes } from "../types/param-types";
import { useFieldArray, useForm } from "react-hook-form";
import { ToastContainer } from "react-toastify";
import { Button } from "react-bootstrap";
import { ClosePurchaseOrderModal } from "../home-page-tabs/openPurchaseOrders/ClosePurchaseOrderModal";
import moment from "moment";
import { ProductInformationOverview } from "../types/product-information-overview";
import { notifyError, notifySuccess } from "../shared/toasts";
import { closePurchaseOrder, EditOrder } from "../../services/apis/postApis";
import { editPurchaseOrderPayloadBuilder } from "./edit-purchase-order-payload-builder";
import { EditPurchaseOrderFormSchemaType, GoodsItemType } from "./form-schemas";
import { v4 as uuidv4 } from "uuid";
import { ProductFinder } from "../shared/product-finder";
import ProductRow from "./product-row";

const EditPurchaseOrder = () => {
  const { purchaseOrderId } = useParams<ParamTypes>();
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const { data, isLoading } = useQuery(["purchaseOrder", purchaseOrderId], () =>
    getPurchaseOrder(purchaseOrderId)
  );

  const defaultValues = useMemo(() => {
    return {
      sellerReference: data?.sellerReference ?? "",
      products:
        data?.orderlinesByItemIdWithReceivedGoods?.map((product) => ({
          itemId: product.itemId,
          goodsItems: product.goodsItems.map((goodsItem) => ({
            amount: goodsItem.quantity?.value ?? 0,
            price: goodsItem.price?.value ?? 0,
            expectedOnDate: goodsItem.productExpectations?.[0]
              ?.expectedDeliveryDate
              ? moment(
                  goodsItem.productExpectations?.[0]?.expectedDeliveryDate
                ).format("YYYY-MM-DD")
              : "",
            name: goodsItem.item?.name ?? "",
            sku: goodsItem.item?.productNumber ?? "",
            orderLineId: goodsItem.orderlineId ?? "",
            item: {
              id: goodsItem.item.id,
              alternativeFor: goodsItem.item.alternativeFor,
              externalIds: goodsItem.item.externalIds,
              productNumber: goodsItem.item.productNumber ?? "",
              ean: goodsItem.item.ean ?? "",
              name: goodsItem.item.name,
              isCombinationProduct: goodsItem.item.isCombinationProduct,
              itemConversionFactor: goodsItem.item.itemConversionFactor,
              subItems: [],
            },
          })),
          receivedGoodsItems: product.receivedGoodsItems.map(
            (receivedGoodsItem) => ({
              name: receivedGoodsItem.goodsItem?.item?.name ?? "",
              processedEventDate: receivedGoodsItem.processedEventDate ?? null,
              reference: receivedGoodsItem.reference ?? "",
              userName: receivedGoodsItem.userName ?? "",
              quantity: receivedGoodsItem.goodsItem.quantity.value ?? 0,
              location: receivedGoodsItem.location ?? [],
            })
          ),
        })) ?? [],
    };
  }, [data]);

  const { register, handleSubmit, reset, control, getValues, watch } =
    useForm<EditPurchaseOrderFormSchemaType>({
      defaultValues: defaultValues,
    });

  const { fields, remove, append, update } = useFieldArray({
    control,
    name: "products",
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const setItemConversionFactor = (
    product: ProductInformationOverview,
    sku: string
  ) => {
    const foundSku = product.supplier?.skus?.find((el) => el.sku === sku);

    if (foundSku) {
      return 1 / foundSku.factor;
    }

    return 1;
  };

  const addToOrder = (product: ProductInformationOverview, sku: string) => {
    const foundProduct = fields.find((el) => el.itemId === product.productId);

    const newGoodsItem = {
      name: product.name ?? "",
      amount: 0,
      expectedOnDate: "",
      sku: sku,
      price: product.purchasePrice,
      orderLineId: uuidv4(),
      item: {
        id: product.productId,
        alternativeFor: "",
        externalIds: product.externalIds,
        productNumber: sku,
        ean: "",
        name: product.name ?? "",
        isCombinationProduct: false,
        itemConversionFactor: setItemConversionFactor(product, sku),
        subItems: [],
      },
    };

    if (!foundProduct) {
      append({
        itemId: product.productId,
        goodsItems: [newGoodsItem],
        receivedGoodsItems: [],
      });
    } else {
      const index = fields.indexOf(foundProduct);

      const values = getValues(`products.${index}`);

      if (values.goodsItems.some((el) => el.sku === sku)) {
        return notifyError("Sku al toegevoegd");
      }

      const newValues = { ...values };

      newValues.goodsItems = [...newValues.goodsItems, newGoodsItem];

      update(index, newValues);
    }
  };

  const removeOrderLine = (index: number) => {
    remove(index);
  };

  const closeOrder = async () => {
    const payLoad = {
      orderId: purchaseOrderId,
      eTag: new Date(),
      orderType: "SalesOrder",
    };

    try {
      const { data } = await closePurchaseOrder(payLoad);
      if (data.success) {
        notifySuccess("Order met succes gesloten");
      } else {
        notifyError("Er ging iets mis met het sluiten van de order");
      }
    } catch (e) {
      notifyError("Er ging iets mis met het sluiten van de order");
    }
  };

  const { mutate } = useMutation(EditOrder, {
    onSuccess: () => {
      notifySuccess("Order met succes bijgewerkt");
    },
    onError: () => {
      notifyError("Order bijwerken is niet gelukt.");
    },
  });

  const submitOrder = (formData: EditPurchaseOrderFormSchemaType) => {
    if (!data)
      return notifyError("Geen orderdata bekend, neem contact op met IT");

    const payLoad = editPurchaseOrderPayloadBuilder({
      purchaseOrderId,
      data,
      formData,
    });

    mutate(payLoad);
  };

  const allProducts = watch("products");

  function calculateTotalPrice(data: { goodsItems: GoodsItemType[] }[]) {
    let totalPrice = 0;
    data.forEach((item) => {
      item.goodsItems.forEach((product) => {
        totalPrice += product.price * product.amount;
      });
    });
    return totalPrice;
  }

  const totalPrice = calculateTotalPrice(allProducts);

  return (
    <>
      <div className="purchaseOrderDetails">
        <Link to={`/`} className="backLink">
          <button style={{ cursor: "pointer" }}>Terug</button>
        </Link>
        {isLoading && <span>Loading...</span>}
        {data && (
          <form onSubmit={handleSubmit(submitOrder)}>
            <h3>Inkooporder bewerken</h3>
            <hr />
            <div className="parties">
              <div className="party">
                <h5>Verkoper</h5>
                <span>{data?.seller?.contact?.name}</span>
                <br />
                Extern referentienummer:
                <input {...register("sellerReference")} type={"text"} />
              </div>
              <div className="party">
                <h5>Koper</h5>
                {data?.buyer?.contact?.name}
                <br />
                Intern referentienummer: <br />
                {data.buyerReference}
              </div>
              <div className="party">
                <h5>Ontvanger</h5>
                <span>{data?.receiver?.contact?.name}</span>
              </div>
            </div>
            <hr />

            <h5 className="mt-2">Bestelde producten</h5>

            <table>
              <thead>
                <tr>
                  <th>Naam</th>
                  <th> SKU</th>
                  <th>Aantal</th>
                  <th>Totaal</th>
                  <th>Verwacht op</th>
                  <th>Prijs ex. BTW</th>
                  <th>Totaal prijs</th>
                  <th>Verwijder</th>
                </tr>
              </thead>
              <tbody>
                {fields.map((field, index) => (
                  <ProductRow
                    key={index}
                    register={register}
                    product={field}
                    removeOrderLine={removeOrderLine}
                    index={index}
                    control={control}
                    getValues={getValues}
                    watch={watch}
                  />
                ))}
                <tr>
                  {Array.from(Array(6).keys()).map((i) => (
                    <td key={i}></td>
                  ))}
                  <td>{totalPrice.toFixed(2)}</td>
                </tr>
              </tbody>
            </table>

            <div className={"d-flex justify-content-between mt-2"}>
              <Button
                variant={"danger"}
                onClick={() => {
                  return handleShowModal();
                }}
              >
                Sluit Order
              </Button>
              <Button variant={"success"} type={"submit"}>
                Wijzigingen verwerken
              </Button>
            </div>
            <hr />
          </form>
        )}

        {data?.seller?.reference && data?.seller?.reference?.length > 30 && (
          <ProductFinder
            supplierId={data?.seller?.reference ?? ""}
            handleAddProduct={addToOrder}
          />
        )}

        {data?.seller?.reference && data?.seller?.reference?.length <= 30 && (
          <p className={"mt-2"}>
            Order is verouderd en er kunnen geen producten meer toegevoegd
            worden
          </p>
        )}
      </div>
      <ToastContainer />
      <ClosePurchaseOrderModal
        show={showModal}
        closeOrder={closeOrder}
        handleClose={() => {
          return setShowModal(false);
        }}
      />
    </>
  );
};

export default EditPurchaseOrder;
