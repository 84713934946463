import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useDebounce } from "../../hooks/useDebounce";
import { getProductsWithSupplier } from "../../services/apis/apis";
import { ProductInformationOverview } from "../types/product-information-overview";
import { ProductRow } from "./product-row";

type Props = {
  supplierId: string;
  handleAddProduct: (product: ProductInformationOverview, sku: string) => void;
};

export const ProductFinder = ({ supplierId, handleAddProduct }: Props) => {
  const [query, setQuery] = useState("");

  const debouncedQuery = useDebounce(query, 300);

  const { data: products } = useQuery(
    ["products", debouncedQuery, supplierId],
    () => getProductsWithSupplier(debouncedQuery ?? "", supplierId)
  );

  return (
    <div className={"mt-4"}>
      <h5>Product toevoegen</h5>
      <label>
        <span>Zoek op naam of artikelnummer: </span>
        <input
          type="text"
          onChange={(e) => setQuery(e.target.value)}
          value={query}
        />
      </label>
      {products && products.length > 0 && (
        <table>
          <thead>
            <tr>
              <th>Naam</th>
              <th>CostPrice</th>
              <th>SKU's</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {products.map((product, index) => (
              <ProductRow
                product={product}
                key={index}
                index={index}
                handleAddProduct={handleAddProduct}
              />
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};
