import styled from 'styled-components';

const MyInput = styled.input`
  border-radius: 4px;
  padding: 10px;
  margin: 0.5rem 0;
  width: 100%;
  background: transparent;
  color: black;
  border: ${(props) => (props.readOnly ? 'none' : '1px solid rgb(220,220,220)')};
  outline: none;
`;

export default MyInput;