import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";

import {
  getLocationStockDataCSV,
  getProductsWithoutDeliveryDetailsCSV,
  getProductsWithPreferredSupplierCSV,
  getProductsWithoutPriceChangeCSV,
  getAllSuppliers,
  getProductCatalog,
} from "../../../services/apis/apis";
import Select from "react-select";
import useFetchAndDownLoadCsv from "../../../hooks/useFetchAndDownLoadCsv";
import { CardBody, DownLoadContainer } from "./CSVDownLoadStyled";
import { ISupplier } from "../../../services/apis/api-types/apis.types";

const locationOptions = [
  { label: "Groningen", value: "Groningen" },
  { label: "Breukelen", value: "Breukelen" },
];

const CSVDownload: React.FC = () => {
  const [selectedLocation, setSelectedLocation] = useState("");
  const [suppliers, setSuppliers] = useState<ISupplier[]>([]);
  const [selectedSupplierId, setSelectedSupplierId] = useState("");

  const [downloadProductsWithoutPriceCSV, WPCisLoading] =
    useFetchAndDownLoadCsv(
      getProductsWithoutPriceChangeCSV,
      "productenZonderPrijsVerschil"
    );
  const [downloadProductsWithoutDeliveryDetailsCSV] = useFetchAndDownLoadCsv(
    getProductsWithoutDeliveryDetailsCSV,
    "productenDeliveryOnbekend"
  );
  const [downloadProductsWithPreferredSupplierCSV] = useFetchAndDownLoadCsv(
    getProductsWithPreferredSupplierCSV,
    "productMetVoorkeursLeverancier"
  );
  const [downloadLocationStockDataCSV] = useFetchAndDownLoadCsv(
    () => getLocationStockDataCSV(selectedLocation),
    `voorraadOp${selectedLocation}`
  );

  const [downloadProductCatalog] = useFetchAndDownLoadCsv(
    () => getProductCatalog(selectedSupplierId),
    `productCatalog${selectedSupplierId}`
  );

  useEffect(() => {
    const fetchSuppliers = async () => {
      const { data } = await getAllSuppliers();
      setSuppliers(data);
    };
    fetchSuppliers();
  }, []);

  return (
    <Card className="mt-3 mb-5">
      <Card.Header>Producten CSV Bestand</Card.Header>
      <CardBody>
        <DownLoadContainer>
          <label>Producten zonder aangepaste VVP:</label>
          <button
            disabled={WPCisLoading}
            onClick={downloadProductsWithoutPriceCSV}
          >
            Download
          </button>
        </DownLoadContainer>
        <DownLoadContainer>
          <label>Producten zonder leveringsdetails:</label>
          <button onClick={downloadProductsWithoutDeliveryDetailsCSV}>
            Download
          </button>
        </DownLoadContainer>
        <DownLoadContainer>
          <label>Producten op locatie:</label>
          <Select
            placeholder="Kies een locatie"
            onChange={(e) => setSelectedLocation(e?.value ?? "")}
            options={locationOptions}
          />
          <button
            disabled={!selectedLocation}
            title={!selectedLocation ? "Kies eerst een locatie" : ""}
            onClick={downloadLocationStockDataCSV}
          >
            Download
          </button>
        </DownLoadContainer>
        <DownLoadContainer>
          <label>CSV op basis Leverancier:</label>
          <Select
            className="mb-3 w-50"
            isSearchable
            placeholder={"Selecteer een leverancier"}
            options={suppliers}
            getOptionLabel={(supplier) => supplier.name}
            getOptionValue={(option) => option.name}
            onChange={(e) => setSelectedSupplierId(e ? e.supplierId : "")}
            defaultValue={suppliers?.filter(
              (supplier) => supplier.supplierId === selectedSupplierId
            )}
          />
          <button
            disabled={!selectedSupplierId}
            title={!selectedSupplierId ? "Kies eerst een leverancier" : ""}
            onClick={downloadProductCatalog}
          >
            Download
          </button>
        </DownLoadContainer>
        <DownLoadContainer>
          <label>Producten met voorkeursleverancier:</label>
          <button onClick={downloadProductsWithPreferredSupplierCSV}>
            Download
          </button>
        </DownLoadContainer>
      </CardBody>
    </Card>
  );
};

export default CSVDownload;
