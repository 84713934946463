import { toast, ToastOptions } from "react-toastify";

const defaultOptions: ToastOptions = {
  position: "top-right",
  autoClose: 1500,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

export const notifySuccess = (message: string): string | number => {
  return toast.success(message, {
    ...defaultOptions,
  });
};

export const notifyWarning = (message: string): string | number => {
  return toast.warn(message, {
    ...defaultOptions,
    autoClose: 3000,
  });
};

export const notifyError = (message: string): string | number => {
  return toast.error(message, {
    ...defaultOptions,
    autoClose: 4000,
  });
};
