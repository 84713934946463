import { ProductInformationOverview } from "../types/product-information-overview";
import { Button } from "react-bootstrap";
import React from "react";
import numeral from "numeral";
import { Link } from "react-router-dom";
import { FaEye } from "react-icons/all";

type Props = {
  product: ProductInformationOverview;
  index: number;
  handleAddProduct: (product: ProductInformationOverview, sku: string) => void;
};

const PIM_PRODUCT_URL = process.env.REACT_APP_pim_url;

export const ProductRow = ({ product, index, handleAddProduct }: Props) => {
  numeral.locale("nl-nl");

  if (!product.supplier?.skus) {
    return (
      <tr
        className={"pb-2 pt-4"}
        style={{ backgroundColor: index % 2 === 0 ? "#ddd" : "white" }}
      >
        <td className={"align-top"}>
          {product.name}
          <Link
            className={"ms-2"}
            target={"_blank"}
            rel={"noreferrer"}
            to={{
              pathname: `${PIM_PRODUCT_URL}edit/product/${product.productId}`,
            }}
          >
            <FaEye />
          </Link>
        </td>
        <td>Prijs onbekend</td>
        <td>Geen SKU</td>
        <td>
          <Button disabled variant={"secondary"} size={"sm"}>
            Toevoegen
          </Button>
        </td>
      </tr>
    );
  } else {
    return (
      <>
        {product.supplier?.skus?.map((sku, j) => (
          <tr
            key={sku.sku}
            className={"pb-2 pt-4"}
            style={{ backgroundColor: index % 2 === 0 ? "#ddd" : "white" }}
          >
            {j === 0 && (
              <td
                className={"align-top"}
                rowSpan={product.supplier?.skus?.length}
              >
                {product.name}
                <Link
                  className={"ms-2"}
                  target={"_blank"}
                  rel={"noreferrer"}
                  to={{
                    pathname: `${PIM_PRODUCT_URL}edit/product/${product.productId}`,
                  }}
                >
                  <FaEye />
                </Link>
              </td>
            )}
            <td>
              {numeral(sku.staffels?.[0]?.price).format("$0.00") ??
                "Prijs onbekend"}
            </td>
            <td>{sku.sku}</td>
            <td>
              <Button
                onClick={() => handleAddProduct(product, sku.sku ?? "")}
                variant={"primary"}
                size={"sm"}
              >
                Toevoegen
              </Button>
            </td>
          </tr>
        ))}
      </>
    );
  }
};
