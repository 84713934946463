import axios from "axios";
import jwt_decode from "jwt-decode";

const ordersApiUri = process.env.REACT_APP_orders_api_url;
const ORDER_PROCESS_API_URL = process.env.REACT_APP_order_processing_api_url;
const READ_ORDER_API_URL = process.env.REACT_APP_read_order_api_url;

function upgradeDataWithUserIdFromHeader(data) {
  let authHeader = axios.defaults.headers.common["Authorization"];
  if (authHeader) {
    //add user id to the data
    const decoded = jwt_decode(authHeader.substr(7)); //remove "bearer "
    data.userId = decoded.sub;
    data.orderType = 2;
  }
}

export const CreateOrder = (createOrderCommand) => {
  //can we get the user id?
  upgradeDataWithUserIdFromHeader(createOrderCommand);
  return axios
    .post(ordersApiUri + "purchaseorder/createOrder", createOrderCommand)
    .then((x) => x.data);
};

export const EditOrder = (editOrderCommand) => {
  upgradeDataWithUserIdFromHeader(editOrderCommand);
  return axios
    .post(ordersApiUri + "purchaseorder/editOrder", editOrderCommand)
    .then((x) => x.data);
};

export const CloseOrder = (closeOrderCommand) => {
  upgradeDataWithUserIdFromHeader(closeOrderCommand);
  return axios
    .post(ordersApiUri + "purchaseorder/closeOrder", closeOrderCommand)
    .then((response) => response.data);
};

export const GetAutomatedPurchaseOrdersProposalOverview = () => {
  return axios
    .get(READ_ORDER_API_URL + "purchaseorder/automated")
    .then((x) => x.data);
};

export const GetAutomatedPurchaseOrdersProposal = (
  sellerId,
  deliveredToId,
  location
) => {
  return axios
    .get(
      READ_ORDER_API_URL +
        `purchaseorder/automated/${sellerId}/${deliveredToId}/${location}`
    )
    .then((x) => x.data);
};

export const getOrderCollection = () => {
  return axios.get(ORDER_PROCESS_API_URL);
};

export const postCollectionToSupplier = (data) => {
  return axios.post(
    ordersApiUri + "purchaseOrder/sendCollectionToSupplier",
    data
  );
};
