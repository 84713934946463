import styled from 'styled-components';

export const CardBody = styled.div`
  padding: 1rem;
  gap: 1rem;
  display: flex;
  flex-direction: column;
`;

export const DownLoadContainer = styled.div`
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #DEE2E6;
  border-radius: 0.25rem;
`;
