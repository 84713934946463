import { ShippingMethod } from "../../services/apis/post-api-types/edit-order-command.type";
import { OrderType } from "../types/orders";
import { INewPurchaseOrderDetails } from "../../services/apis/api-types/purchase-order-details.type";
import { EditPurchaseOrderFormSchemaType } from "./form-schemas";

type Props = {
  purchaseOrderId: string;
  data: INewPurchaseOrderDetails;
  formData: EditPurchaseOrderFormSchemaType;
};

export const editPurchaseOrderPayloadBuilder = ({
  purchaseOrderId,
  data,
  formData,
}: Props) => {
  console.log({ formData });
  return {
    orderId: purchaseOrderId,
    eTag: new Date(),
    order: {
      id: purchaseOrderId,
      buyerReference: data?.buyerReference ?? "",
      sellerReference: formData.sellerReference ?? "",
      shipping: {
        shippingMethod:
          data?.shipping?.shippingMethod ?? ShippingMethod.Dropshipment,
        shippingCost: {
          currency: data?.shipping.shippingCost?.currency ?? "",
          value: data?.shipping.shippingCost?.value ?? 0,
        },
        deliveryAddress: {
          country: data?.shipping.deliveryAddress?.country ?? "",
          countryCode: data?.shipping.deliveryAddress?.countryCode ?? "",
          latLong: {
            latitude: data?.shipping.deliveryAddress?.latLong?.latitude ?? 0,
            longitude: data?.shipping.deliveryAddress?.latLong?.longitude ?? 0,
          },
          number: data?.shipping.deliveryAddress?.number ?? "",
          numberAddition: data?.shipping.deliveryAddress?.numberAddition ?? "",
          place: data?.shipping.deliveryAddress?.place ?? "",
          street: data?.shipping.deliveryAddress?.street ?? "",
          zip: data?.shipping.deliveryAddress?.zip ?? "",
        },
      },
      orderType: OrderType.PurchaseOrder,
      buyer: {
        reference: data.buyer?.reference ?? "",
        contact: {
          name: data.buyer?.contact?.name ?? "",
          contactName: data.buyer?.contact?.contactName ?? "",
          phone: data.buyer?.contact?.phone ?? "",
          email: data.buyer?.contact?.email ?? "",
        },
        address: {
          street: data.buyer?.address?.street ?? "",
          number: data.buyer?.address?.number ?? "",
          numberAddition: data.buyer?.address?.numberAddition ?? "",
          zip: data.buyer?.address?.zip ?? "",
          place: data.buyer?.address?.place ?? "",
          country: data.buyer?.address?.country ?? "",
          countryCode: data.buyer?.address?.countryCode ?? "",
          latLong: {
            latitude: data.buyer?.address?.latLong?.latitude ?? 0,
            longitude: data.buyer?.address?.latLong?.longitude ?? 0,
          },
        },
      },
      seller: {
        reference: data.seller?.reference ?? "",
        contact: {
          name: data.seller?.contact?.name ?? "",
          contactName: data.seller?.contact?.contactName ?? "",
          phone: data.seller?.contact?.phone ?? "",
          email: data.seller?.contact?.email ?? "",
        },
        address: {
          street: data.seller?.address?.street ?? "",
          number: data.seller?.address?.number ?? "",
          numberAddition: data.seller?.address?.numberAddition ?? "",
          zip: data.seller?.address?.zip ?? "",
          place: data.seller?.address?.place ?? "",
          country: data.seller?.address?.country ?? "",
          countryCode: data.seller?.address?.countryCode ?? "",
          latLong: {
            latitude: data.seller?.address?.latLong?.latitude ?? 0,
            longitude: data.seller?.address?.latLong?.longitude ?? 0,
          },
        },
      },
      receiver: {
        reference: data.receiver?.reference ?? "",
        contact: {
          name: data.receiver?.contact?.name ?? "",
          contactName: data.receiver?.contact?.contactName ?? "",
          phone: data.receiver?.contact?.phone ?? "",
          email: data.receiver?.contact?.email ?? "",
        },
        address: {
          street: data.receiver?.address?.street ?? "",
          number: data.receiver?.address?.number ?? "",
          numberAddition: data.receiver?.address?.numberAddition ?? "",
          zip: data.receiver?.address?.zip ?? "",
          place: data.receiver?.address?.place ?? "",
          country: data.receiver?.address?.country ?? "",
          countryCode: data.receiver?.address?.countryCode ?? "",
          latLong: {
            latitude: data.receiver?.address?.latLong?.latitude ?? 0,
            longitude: data.receiver?.address?.latLong?.longitude ?? 0,
          },
        },
      },
      goodsItems: formData.products.flatMap((product) =>
        product.goodsItems.map((goodsItem) => ({
          orderlineId: goodsItem.orderLineId ?? "",
          price: {
            currency: "eur",
            value: goodsItem.price,
            vatPercentage: 21,
            valueIncludesVat: true,
            vatExempted: false,
          },
          quantity: {
            unit: "",
            value: goodsItem.amount,
          },
          item: {
            ...goodsItem.item,
            subItems: [],
          },
          productExpectations: [
            {
              productId: product.itemId,
              deliveryReference: formData?.sellerReference ?? "",
              productAmount: {
                unit: "",
                value: goodsItem.amount,
              },
              expectedDeliveryDate: new Date(goodsItem.expectedOnDate),
            },
          ].filter((el) => !!el.expectedDeliveryDate.getTime()),
          totalNumberOfUnits: 0,
        }))
      ),
      payments: [],
      serviceItems: [],
    },
    orderType: OrderType.PurchaseOrder,
  };
};
