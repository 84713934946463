import {CircleSpinner} from 'react-spinners-kit';
import styled from 'styled-components';
import React from 'react';

const SpinnerWrapper = styled('div')<{inline: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
  height: ${({inline}) => (inline ? '100%' : '90vh')};
`;

interface Props {
  loading: boolean;
  inline: boolean;
  button?: boolean;
  onClick?: () => void;
}

const LoadingSpinner: React.FC<Props> = ({loading, inline, button, onClick}) => {
  return (
    <SpinnerWrapper inline={inline}>
      <CircleSpinner size={30} color='#686769' loading={loading} />
      {button && (
        <button className='btn btn-primary btn-sm mt-3' onClick={onClick}>
          Terug naar overzicht
        </button>
      )}
    </SpinnerWrapper>
  );
};

const LoadingView: React.FC<Props> = ({button, loading, inline, onClick}) => {
  return (
    <div>
      <LoadingSpinner
        loading={loading}
        inline={inline}
        button={button}
        onClick={onClick}
      />
    </div>
  );
};

export default LoadingView;
