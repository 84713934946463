import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  GetAutomatedPurchaseOrdersProposal,
  CreateOrder,
} from "../services/ordersService";
import { FaTrash as Trash } from "react-icons/fa";
import "../styles/purchaseOrderDetails.scss";
import numeral from "numeral";
import moment from "moment";
import "numeral/locales";
import update from "immutability-helper";
import { toast, ToastContainer } from "react-toastify";
import { getDeliveryAddress } from "../utils/deliveryAddresses";
import { getReceiverData } from "../utils/receivers";
import { getBuyerData } from "../utils/buyers";
import { OrderType } from "../utils/orderType";
import { getSupplierDetails } from "../services/apis/apis";

class CreateAutomatedPurchaseOrder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      linesWithError: 0,
      purchaseOrder: {},
      expectedDeliveryDate: null,
    };

    this.handleExpectedDeliveryChange =
      this.handleExpectedDeliveryChange.bind(this);
  }

  componentDidMount() {
    let sellerId = this.props.match.params.sellerId;
    let deliveredToId = this.props.match.params.deliveredToId;
    let deliveredTo = this.props.match.params.deliveredTo;
    let location = deliveredTo.split(" ")[1];

    GetAutomatedPurchaseOrdersProposal(sellerId, deliveredToId, location).then(
      (rst) => {
        rst.reference = moment.utc().format("[APO-]YYMMDD-HHmmss");
        rst.orderedProducts.forEach(
          (x) => (x.alteredOrderedAmount = x.orderedAmount)
        );

        this.setState({
          linesWithError: 0,
          purchaseOrder: rst,
        });
      }
    );
  }

  handleExpectedDeliveryChange(event) {
    this.setState({ expectedDeliveryDate: event.target.value });
  }

  handleOrderedAmountChanged(productLine, evt) {
    let idx = this.state.purchaseOrder.orderedProducts.indexOf(productLine);

    let linesWithError = this.state.linesWithError;
    let newLineHasError = !(evt.target.value > 0 && productLine.costPrice > 0);
    if (productLine.hasError) {
      if (!newLineHasError) linesWithError -= 1;
    } else {
      if (newLineHasError) linesWithError += 1;
    }

    let newPurchaseOrder = update(this.state.purchaseOrder, {
      orderedProducts: {
        [idx]: {
          hasError: { $set: newLineHasError },
          alteredOrderedAmount: { $set: evt.target.value },
        },
      },
    });

    this.setState({
      linesWithError: linesWithError,
      purchaseOrder: newPurchaseOrder,
    });
  }

  handleCostPriceChanged(productLine, evt) {
    let idx = this.state.purchaseOrder.orderedProducts.indexOf(productLine);

    let linesWithError = this.state.linesWithError;
    let newLineHasError = !(
      evt.target.value > 0 && productLine.alteredOrderedAmount > 0
    );
    if (productLine.hasError) {
      if (!newLineHasError) linesWithError -= 1;
    } else {
      if (newLineHasError) linesWithError += 1;
    }

    let newPurchaseOrder = update(this.state.purchaseOrder, {
      orderedProducts: {
        [idx]: {
          hasError: { $set: newLineHasError },
          costPrice: { $set: evt.target.value },
        },
      },
    });
    this.setState({
      linesWithError: linesWithError,
      purchaseOrder: newPurchaseOrder,
    });
  }

  removeProductLine(productLine) {
    let idx = this.state.purchaseOrder.orderedProducts.indexOf(productLine);
    let linesWithError = this.state.linesWithError;
    if (productLine.hasError) {
      linesWithError -= 1;
    }

    let newPurchaseOrder = update(this.state.purchaseOrder, {
      orderedProducts: {
        $splice: [[idx, 1]],
      },
    });

    this.setState({
      linesWithError: linesWithError,
      purchaseOrder: newPurchaseOrder,
    });
  }

  stripProperty(obj, prop) {
    const entries = Object.entries(obj).map(([key, value]) => {
      if (!!value && typeof value === "object") {
        value = this.stripProperty(value, prop);
      }
      return [key, value];
    });

    return Object.fromEntries(entries.filter(([key]) => key !== prop));
  }

  async saveOrder() {
    //delivery address
    let po = this.state.purchaseOrder;

    let { data: seller } = await getSupplierDetails(po.seller.reference);
    const strippedSeller = this.stripProperty(seller, "$type");
    strippedSeller.reference = strippedSeller.supplierId;
    let deliveryAddress = getDeliveryAddress(po.deliveredToId);
    let receiver = getReceiverData(po.deliveredToId);

    //hack
    strippedSeller.contact.name = strippedSeller.name;

    //create the order to store
    let orderToCreate = {
      id: po.id,
      buyerReference: po.reference,
      shipping: {
        shippingMethod: 1,
        shippingCost: {
          currency: "€",
          value: 0,
        },
        deliveryAddress: deliveryAddress,
      },
      orderType: OrderType.PurchaseOrder,
      buyer: getBuyerData(receiver.reference),
      seller: strippedSeller,
      receiver: receiver,
      goodsitems: [],
    };

    //add the items
    po.orderedProducts.forEach((product) => {
      orderToCreate.goodsitems.push({
        price: {
          currency: "€",
          value: product.costPrice,
          vatPercentage: 21,
          valueIncludesVat: false,
          vatExempted: false,
        },
        quantity: {
          unit: "stuks",
          value: product.alteredOrderedAmount,
        },
        item: {
          id: product.id,
          externalIds: [],
          productNumber: product.externalId,
          name: product.name,
          itemConversionFactor: product.itemConversionFactor,
        },
      });
    });

    let createOrder = {
      order: orderToCreate,
      sentToSupplier: false,
      eTag: po.eTag,
    };
    if (this.state.expectedDeliveryDate !== null) {
      createOrder.expectedDeliveryDate = new Date(
        this.state.expectedDeliveryDate
      ).toJSON();
    }

    CreateOrder(createOrder).then(
      (response) => {
        if (response.success === true) {
          toast.success("Product met succes verstuurd", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      },
      () => {
        toast.error("Er is iets mis gegaan", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    );
  }

  render() {
    let po = this.state.purchaseOrder;

    numeral.locale("nl-nl");
    return (
      <div className="purchaseOrderDetails">
        <Link to={`/`} className="backLink">
          <button style={{ cursor: "pointer" }}>Terug</button>
        </Link>
        {(po.id && (
          <>
            <h4>APO aanmaken</h4>
            {po.id}
            <hr />
            <div className="parties">
              <div className="party">
                <h5>Verkoper</h5>
                <span>{po.seller.contact.name}</span>
                <br />
              </div>
              <div className="party">
                <h5>Koper</h5>
                {po.buyer}
                <br />
                ref: {po.reference}
              </div>
              <div className="party">
                <h5>Ontvanger</h5>
                <span>{po.deliveredTo}</span>
              </div>
              <div className="party">
                <h5>Verwachte leverdatum</h5>
                <input
                  type="date"
                  onChange={this.handleExpectedDeliveryChange}
                />
              </div>
            </div>
            <hr />
            <h5>Bestelde producten</h5>
            <table className="orderProductsTable">
              <thead>
                <tr>
                  <th className="trash">&nbsp;</th>
                  <th className="product">Product</th>
                  <th className="articleNumber">Artikelnummer</th>
                  <th className="amount">Voorgesteld</th>
                  <th className="amount">Te bestellen</th>
                  <th className="money">Prijs ex. BTW</th>
                  <th className="money">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {po.orderedProducts.map((p) => (
                  <tr key={p.id} className={`${p.hasError ? "withError" : ""}`}>
                    <td className="trash">
                      <Trash
                        className="removeIcon"
                        onClick={() => this.removeProductLine(p)}
                      />
                    </td>
                    <td>{p.name}</td>
                    <td className="articleNumber">{p.externalId}</td>
                    <td className="amount">{p.orderedAmount}</td>
                    <td className="amount">
                      <input
                        className="intInput"
                        type="number"
                        onChange={(evt) =>
                          this.handleOrderedAmountChanged(p, evt)
                        }
                        value={p.alteredOrderedAmount}
                      />
                    </td>
                    <td className="money">
                      <input
                        className="decimalInput"
                        onChange={(evt) => this.handleCostPriceChanged(p, evt)}
                        value={p.costPrice}
                      />
                    </td>
                    <td className="money">
                      {(p.costPrice &&
                        numeral(p.alteredOrderedAmount * p.costPrice).format(
                          "$0.00"
                        )) ||
                        null}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="6"></td>
                  <td className="total money">
                    {numeral(
                      po.orderedProducts.reduce(
                        (acc, p) => acc + p.alteredOrderedAmount * p.costPrice,
                        0
                      )
                    ).format("$0.00")}
                  </td>
                </tr>
              </tfoot>
            </table>

            <div className="actionBar">
              <button
                disabled={this.state.linesWithError > 0}
                style={{ cursor: "pointer" }}
                onClick={() => this.saveOrder()}
              >
                Opslaan
              </button>
            </div>
          </>
        )) || <span>Loading...</span>}
        <ToastContainer />
      </div>
    );
  }
}

export default CreateAutomatedPurchaseOrder;
