import {useCallback, useState} from 'react';
import {AxiosResponse} from 'axios';

const useFileUpload = <T>(postCall: (postObject: T) => Promise<AxiosResponse>) => {
  const [loading, setLoading] = useState(false);

  const postFile = useCallback(
    async (postObject) => {
      setLoading(true);
      const response = await postCall(postObject);
      if (response) {
        setLoading(false);
        return response;
      }
      setLoading(false);
    },
    [postCall],
  );

  return [postFile, loading] as const;
};

export default useFileUpload;
