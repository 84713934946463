import React, { FC, Fragment, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/all";
import {
  Control,
  FieldArray,
  useFieldArray,
  UseFormGetValues,
  UseFormRegister,
  UseFormWatch,
} from "react-hook-form";
import ReceivedGoodsOverview from "./received-goods-overview";
import { Button } from "react-bootstrap";
import numeral from "numeral";
import { FaTrash } from "react-icons/fa";
import { EditPurchaseOrderFormSchemaType } from "./form-schemas";

type Props = {
  register: UseFormRegister<EditPurchaseOrderFormSchemaType>;
  product: FieldArray<EditPurchaseOrderFormSchemaType, "products">;
  removeOrderLine: (index: number) => void;
  index: number;
  control: Control<EditPurchaseOrderFormSchemaType, "products">;
  getValues: UseFormGetValues<EditPurchaseOrderFormSchemaType>;
  watch: UseFormWatch<EditPurchaseOrderFormSchemaType>;
};

const ProductRow: FC<Props> = ({
  register,
  product,
  index,
  removeOrderLine,
  control,
  getValues,
  watch,
}) => {
  const [showTableRow, setShowTableRow] = useState(false);

  const { remove } = useFieldArray({
    control,
    name: `products.${index}.goodsItems`,
  });

  const handleRemoveOrderLine = (nestedIndex: number) => {
    remove(nestedIndex);

    const values = getValues("products");

    const parent = values.find((el) => el.itemId === product.itemId);

    if (parent?.goodsItems?.length === 0) {
      removeOrderLine(index);
    }
  };

  const goodsItems = watch(`products.${index}.goodsItems`);

  return (
    <Fragment>
      {goodsItems.map((goodsItem, nestedIndex) => (
        <tr key={nestedIndex}>
          {nestedIndex === 0 && (
            <td className={"align-top"} rowSpan={goodsItems.length}>
              <div
                className={"d-flex justify-content-between align-items-start"}
              >
                <div>{product.goodsItems[0].name}</div>
                {product.receivedGoodsItems?.length > 0 && (
                  <Button
                    onClick={() => setShowTableRow(!showTableRow)}
                    className=" btn-light btn-sm"
                    style={{ border: "none", boxShadow: "none" }}
                  >
                    {showTableRow ? (
                      <FaChevronUp style={{ color: "gray" }} />
                    ) : (
                      <FaChevronDown style={{ color: "gray" }} />
                    )}
                  </Button>
                )}
              </div>
            </td>
          )}
          <td>{goodsItem.sku}</td>
          <td>
            <input
              className="intInput text-right"
              type="number"
              {...register(
                `products.${index}.goodsItems.${nestedIndex}.amount`
              )}
            />
          </td>
          <td>{goodsItem.amount * goodsItem.item.itemConversionFactor}</td>
          <td>
            <input
              type={"date"}
              {...register(
                `products.${index}.goodsItems.${nestedIndex}.expectedOnDate`
              )}
            />
          </td>
          <td>
            <input
              className="decimalInput text-right ml-1"
              {...register(`products.${index}.goodsItems.${nestedIndex}.price`)}
            />
          </td>
          <td>
            {numeral(
              watch(`products.${index}.goodsItems.${nestedIndex}.price`) *
                watch(`products.${index}.goodsItems.${nestedIndex}.amount`)
            ).format("€0.00")}
          </td>
          <td>
            <FaTrash
              className="removeIcon"
              onClick={() => handleRemoveOrderLine(nestedIndex)}
            />
          </td>
        </tr>
      ))}
      {showTableRow && product.receivedGoodsItems.length > 0 && (
        <ReceivedGoodsOverview
          receivedGoodsItems={product.receivedGoodsItems}
        />
      )}
    </Fragment>
  );
};

export default ProductRow;
