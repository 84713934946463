import { applyMiddleware, compose, createStore } from "redux";
import { loadUser } from "redux-oidc";
import rootReducer from "./index";
import userManager from "../utils/userManager";
import axios from "axios";

const loggerMiddleware = (store) => (next) => (action) => {
  if (process.env.REACT_APP_LOG_REDUX) {
    console.log("Action type:", action.type);
    console.log("Action payload:", action.payload);
    console.log("State before:", store.getState());
  }
  next(action);
  if (process.env.REACT_APP_LOG_REDUX)
    console.log("State after:", store.getState());
};

const axiosHeaderMiddleWare = () => (next) => (action) => {
  let token = null;
  if (action.type === "redux-oidc/USER_FOUND") {
    token = action.payload.access_token;
  }

  axios.defaults.headers.common["Authorization"] = token
    ? "Bearer " + token
    : null;
  next(action);
};

const initialState = {};

const createStoreWithMiddleware = compose(
  applyMiddleware(loggerMiddleware, axiosHeaderMiddleWare)
)(createStore);

const store = createStoreWithMiddleware(rootReducer, initialState);
loadUser(store, userManager);

export default store;
