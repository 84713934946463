import {useCallback, useState} from 'react';
import {downloadCsvFromString} from '../utils/downloadCsvHelpers';

const useFetchAndDownLoadCsv = (request: () => Promise<string>, fileName: string) => {
  const [loading, setLoading] = useState(false);

  const handler = useCallback(
    async () => {
      setLoading(true);
      const fileData = await request();
      setLoading(false);
      downloadCsvFromString(fileData, fileName);
    },
    [request, fileName],
  );

  return [handler, loading] as const;
};

export default useFetchAndDownLoadCsv;
