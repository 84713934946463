import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { Route, Switch, Redirect } from "react-router";
import { BrowserRouter as Router } from "react-router-dom";
import CreatePurchaseOrder from "./components/create-purchase-order";
import Callback from "./components/auth/callback";
import "react-toastify/dist/ReactToastify.css";
import "./styles/main.scss";
import { Provider } from "react-redux";
import { OidcProvider } from "redux-oidc";
import store from "./redux/store";
import userManager from "./utils/userManager";
import PrivateRoute from "./components/auth/privateRoute";
import ProductDetails from "./components/home-page-tabs/minimumStock/ProductDetails";
import { ToastContainer } from "react-toastify";
import CSVUpload from "./components/home-page-tabs/csvUpload/CSVUpload";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import CreateAutomatedPurchaseOrder from "./components/create-automated-purchase-order";
import Home from "./components/home";
import Navigation from "./components/navbar/Navigation";
import EditPurchaseOrder from "./components/edit-purchase-order/edit-purchase-order";

const queryClient = new QueryClient();

const RedirectToHome = () => <Redirect to="/home" />;

const App = () => {
  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <OidcProvider store={store} userManager={userManager}>
            <Router>
              <Navigation />
              <main>
                <Switch>
                  <PrivateRoute name="home" path="/home" component={Home} />
                  <PrivateRoute
                    role="Inkoper"
                    name="createAutomatedPurchaseOrder"
                    path="/create-auto/:sellerId/:deliveredToId/:deliveredTo/"
                    component={CreateAutomatedPurchaseOrder}
                  />
                  <PrivateRoute
                    role="Inkoper"
                    name="createPurchaseOrder"
                    path="/create/"
                    component={CreatePurchaseOrder}
                  />
                  <PrivateRoute
                    name="purchaseOrderDetails"
                    path="/edit/:purchaseOrderId/"
                    component={EditPurchaseOrder}
                  />
                  <PrivateRoute
                    role="Inkoper"
                    name="productSearch"
                    path="/product_details/:productId"
                    component={ProductDetails}
                  />
                  <PrivateRoute
                    role="Inkoper"
                    name="csv-upload"
                    path="/csv-upload"
                    component={CSVUpload}
                  />
                  <Route
                    exact={true}
                    path="/signin-oidc"
                    component={Callback}
                  />
                  <Route component={RedirectToHome} />
                </Switch>
              </main>
              <ToastContainer />
            </Router>
          </OidcProvider>
        </Provider>
      </QueryClientProvider>
    </React.StrictMode>
  );
};

const rootElement = document.getElementById("root");
ReactDOM.render(<App/>, rootElement);
