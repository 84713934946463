import React, { FC } from "react";
import { uploadMinimumStockCsvFile } from "../../../services/apis/postApis";
import { notifyError } from "../../shared/toasts";
import FileUploadForm from "./FileUploadForm";
import handleCsvUploadResponse from "./handleCsvUploadResponse";
import useFileUpload from "../../../hooks/useFileUpload";

interface Props {
  selectedLocation: string;
}

export interface UploadFieldValues {
  file: FileList;
}

const UpLoadMinimumStockFileInput: FC<Props> = ({ selectedLocation }) => {
  const [postFile, loading] = useFileUpload(uploadMinimumStockCsvFile);

  const handlePostMinimumStockData = async (data: UploadFieldValues) => {
    if (!data.file.length) {
      return notifyError("Geen bestand geselecteerd");
    } else if (!selectedLocation) {
      return notifyError("Selecteer een locatie.");
    }

    const formData = new FormData();
    formData.append("path", "Gadero");
    formData.append("path", selectedLocation);
    formData.append("file", data.file[0]);

    handleCsvUploadResponse(postFile(formData));
  };

  return (
    <FileUploadForm loading={loading} submitData={handlePostMinimumStockData} />
  );
};

export default UpLoadMinimumStockFileInput;


