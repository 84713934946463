export interface EditOrderCommand {
  orderId: string;
  orderType: OrderType;
  userId?: string;
  eTag: Date;
  order?: Order;
}

type OrderType = typeof OrderType[keyof typeof OrderType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
const OrderType = {
  SalesOrder: "SalesOrder",
  PurchaseOrder: "PurchaseOrder",
  CustomerReturnOrder: "CustomerReturnOrder",
  LocalSalesOrder: "LocalSalesOrder",
  ServiceOrder: "ServiceOrder",
  PurchaseCommitment: "PurchaseCommitment",
  SupplierReturnOrder: "SupplierReturnOrder",
} as const;

interface Order {
  id: string;
  buyerReference?: string;
  sellerReference?: string;
  shipping?: Shipping;
  orderType: OrderType;
  buyer?: FinancialParty;
  seller?: FinancialParty;
  receiver?: Party;
  customerComment?: string;
  initialOrderDate?: Date;
  payments: IPayment[];
  goodsItems: GoodsItem[];
  serviceItems: ServiceItem[];
}

interface Shipping {
  shippingMethod: ShippingMethod;
  shippingCost?: Amount;
  deliveryAddress?: Address;
}

type ShippingMethod = typeof ShippingMethod[keyof typeof ShippingMethod];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ShippingMethod = {
  LocalPickup: "LocalPickup",
  Delivered: "Delivered",
  Courier: "Courier",
  Stock: "Stock",
  TransShipment: "TransShipment",
  Express: "Express",
  Dropshipment: "Dropshipment",
} as const;

interface Amount {
  currency?: string;
  value: number;
}

interface Address {
  street?: string;
  number?: string;
  numberAddition?: string;
  zip?: string;
  place?: string;
  country?: string;
  countryCode?: string;
  latLong?: GeoCoordinates;
}

interface GeoCoordinates {
  latitude: number;
  longitude: number;
}

type FinancialParty = Party & FinancialPartyAllOf;

interface Party {
  reference?: string;
  contact?: Contact;
  address?: Address;
}

interface Contact {
  name?: string;
  contactName?: string;
  phone?: string;
  email?: string;
}

type FinancialPartyAllOf = {
  chamberOfCommerceNumber?: string;
  vatNumber?: string;
};

interface IPayment {
  amountPaid?: Amount;
  paymentReference?: string;
  balanceType: BalanceType;
}

type BalanceType = typeof BalanceType[keyof typeof BalanceType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
const BalanceType = {
  Debit: "Debit",
  Credit: "Credit",
} as const;

interface GoodsItem {
  orderlineId: string;
  price?: Price;
  quantity?: Quantity;
  item?: Item;
  productExpectations: ProductExpectedOn[];
  totalNumberOfUnits: number;
}

type Price = Amount & PriceAllOf;

type PriceAllOf = {
  vatPercentage: number;
  valueIncludesVat: boolean;
  vatExempted: boolean;
};

interface Quantity {
  unit?: string;
  value: number;
}

interface Item {
  id: string;
  alternativeFor: string;
  externalIds: string[];
  productNumber?: string;
  ean?: string;
  name?: string;
  dimensions?: Dimensions;
  subItems: GoodsItem[];
  isCombinationProduct: boolean;
  itemConversionFactor: number;
  i18n?: ItemI18n;
}

interface Dimensions {
  length: number;
  width: number;
  height: number;
}

type ItemI18n = { [key: string]: { [key: string]: string } };

interface ProductExpectedOn {
  productId: string;
  deliveryReference?: string;
  productAmount?: Quantity;
  expectedDeliveryDate: Date;
}

interface ServiceItem {
  id: string;
  plu?: string;
  description?: string;
  price?: Price;
}
