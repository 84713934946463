import React, {FC} from 'react';
import {Col, Row} from 'react-bootstrap';
import MyInput from '../../shared/MyInput';
import {useForm} from 'react-hook-form';
import {UploadFieldValues} from './UploadMinimumStockFileInput';
import LoadingView from '../../shared/LoadingView';

interface Props {
  submitData: (data: UploadFieldValues) => Promise<string | number | undefined>;
  loading: boolean;
}

const FileUploadForm: FC<Props> = ({submitData, loading}) => {

  const {handleSubmit, register} = useForm<UploadFieldValues>();

  return (
    <form onSubmit={handleSubmit(submitData)}>
      <Row>
        <Col>
          <MyInput type='file'
                   {...register('file')}
          />
        </Col>
      </Row>
      <Row>
        <Col className='d-flex justify-content-end mt-3 '>
          {loading ? <LoadingView inline loading={loading} /> :
            <input type='submit' className='btn btn-success' />
          }
        </Col>
      </Row>
    </form>
  );
};

export default FileUploadForm;
