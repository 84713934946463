import React, { FC } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { OpenPurchaseOrdersType } from "../../../services/apis/api-types/open-purchase-orders.type";

interface Props {
  openPurchaseOrder: OpenPurchaseOrdersType;
}

export function notEmpty<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

const OpenPurchaseOrderTableRow: FC<Props> = ({ openPurchaseOrder }) => {
  const goodsItems =
    openPurchaseOrder.orderlinesByItemIdWithReceivedGoods.flatMap((orderLine) =>
      orderLine.goodsItems.map((goodsItem) => goodsItem)
    );

  const dates = goodsItems.flatMap((goodsItem) =>
    goodsItem.productExpectations?.map(
      (expectation) => new Date(expectation.expectedDeliveryDate || "")
    )
  );

  const filteredDates = dates.filter(notEmpty);

  const min = filteredDates.reduce(function (a, b) {
    return a < b ? a : b;
  }, filteredDates[0]);

  return (
    <tr key={openPurchaseOrder.id}>
      <td>{openPurchaseOrder?.seller?.contact?.name}</td>
      <td>{openPurchaseOrder?.buyer?.contact?.name}</td>
      <td>{moment(openPurchaseOrder.initialOrderDate).format("DD-MM-YYYY")}</td>
      <td>{min ? moment(min).format("DD-MM-YYYY") : "Datum onbekend"}</td>
      <td>{openPurchaseOrder.buyerReference}</td>
      <td className="text-right">
        {openPurchaseOrder.checkedInCount} van{" "}
        {openPurchaseOrder.totalGoodsCount}
      </td>
      <td>
        <Link to={`/edit/${openPurchaseOrder.id}`}>
          <Button className="btn btn-sm btn-light float-right">OPEN</Button>
        </Link>
      </td>
    </tr>
  );
};

export default OpenPurchaseOrderTableRow;
