import React from 'react';
import {Route} from 'react-router-dom';

import {connect} from 'react-redux';
import userManager from '../../utils/userManager';

function PrivateRoute({component: Component, role, ...rest}) {
  let redirecting = false;
  return (
    <Route {...rest} render={(props) => {
      if (rest.oidc.isLoadingUser) return null; //don't know still starting up

      if (!rest.oidc.user || rest.oidc.user.expired) {
        if (!redirecting) {
          localStorage.setItem('redirectUri', window.location.href);
          userManager.signinRedirect();
          redirecting = true;
        }

        return <div>U wordt doorverwezen naar de login pagina</div>;
      }

      redirecting = false;

      if (role) {
        if (!rest.oidc.user.profile.roles || !rest.oidc.user.profile.roles.includes(role)) {
          return <div>U heeft niet de correcte rol</div>;
        }
      }
      return <Component {...props} />;
    }
    }
    />
  );
}

const mapStateToProps = (state) => ({
  oidc: state.oidc,
});

export default connect(mapStateToProps)(PrivateRoute);