import axios, { AxiosResponse } from "axios";
import jwt_decode from "jwt-decode";

import {
  IEditOrderCommand,
  IProductionOrderCancelPostObject,
  IProductionOrderCompletePostObject,
} from "./postApis.types";
import {
  IClosePurchaseOrderPostObject,
  ICreateProductionOrderPostObject,
  INewShelfPostObject,
  IPostApiDataObject,
  IProductionOrderAcceptPostObject,
  ISetMinimumStockPostObject,
} from "./api-types/apis.types";
import { EditOrderCommand } from "./post-api-types/edit-order-command.type";

const STOCK_POST_API_URL = process.env.REACT_APP_stock_post_api_url;
const PURCHASE_ORDER_API_URL = process.env.REACT_APP_inkoop_api_url;
const POST_API_URL = process.env.REACT_APP_command_api_url;
const PRODUCT_POST_API_URL = process.env.REACT_APP_product_api;

const upgradeDataWithUserIdFromHeader = (
  data:
    | IProductionOrderCancelPostObject
    | IProductionOrderCompletePostObject
    | ICreateProductionOrderPostObject
    | INewShelfPostObject
    | IClosePurchaseOrderPostObject
    | IProductionOrderAcceptPostObject
    | ISetMinimumStockPostObject
    | IEditOrderCommand
) => {
  const authHeader = axios.defaults.headers.common["Authorization"];

  if (authHeader) {
    //add user id to the data
    const decoded: { sub: string } = jwt_decode(authHeader.substr(7)); //remove "bearer "

    data.userId = decoded.sub;

    return;
  }

  return;
};

export const postCompleteProductionOrder = (
  postObject: IProductionOrderCompletePostObject
): Promise<AxiosResponse<IPostApiDataObject>> => {
  upgradeDataWithUserIdFromHeader(postObject);

  return axios.post(
    `${STOCK_POST_API_URL}productionOrder/complete`,
    postObject
  );
};

export const postCancelProductionOrder = (
  postObject: IProductionOrderCancelPostObject
): Promise<AxiosResponse<IPostApiDataObject>> => {
  upgradeDataWithUserIdFromHeader(postObject);

  return axios.post(`${STOCK_POST_API_URL}productionOrder/cancel`, postObject);
};

// STOCK API
export const postCreateProductionOrder = (
  data: ICreateProductionOrderPostObject
): Promise<AxiosResponse<IPostApiDataObject>> => {
  upgradeDataWithUserIdFromHeader(data);

  return axios.post(`${STOCK_POST_API_URL}productionOrder/create`, data);
};

export const postNewShelf = (
  data: INewShelfPostObject
): Promise<AxiosResponse<IPostApiDataObject>> => {
  upgradeDataWithUserIdFromHeader(data);

  return axios.post(
    `${STOCK_POST_API_URL}stockItems/createProductStockLocation`,
    data
  );
};

export const EditOrder = (data: EditOrderCommand) => {
  upgradeDataWithUserIdFromHeader(data);
  return axios.post(PURCHASE_ORDER_API_URL + "purchaseorder/editOrder", data);
};

export const closePurchaseOrder = (
  data: IClosePurchaseOrderPostObject
): Promise<AxiosResponse<IPostApiDataObject>> => {
  upgradeDataWithUserIdFromHeader(data);

  return axios.post(PURCHASE_ORDER_API_URL + "purchaseOrder/closeOrder", data);
};

export const postAcceptProductionOrder = (
  postObject: IProductionOrderAcceptPostObject
): Promise<AxiosResponse<IPostApiDataObject>> => {
  upgradeDataWithUserIdFromHeader(postObject);

  return axios.post(`${STOCK_POST_API_URL}productionOrder/accept`, postObject);
};

export const postMinimumStock = (
  data: ISetMinimumStockPostObject
): Promise<AxiosResponse<IPostApiDataObject>> => {
  upgradeDataWithUserIdFromHeader(data);

  return axios.post(POST_API_URL + "productinformation/setMinimumStock", data);
};

export const uploadProductCsvFile = (
  postObject: FormData
): Promise<AxiosResponse> => {
  return axios.post(PRODUCT_POST_API_URL + "product/import", postObject);
};

export const uploadPreferredSupplierCsvFile = (
  postObject: FormData
): Promise<AxiosResponse> => {
  return axios.post(
    PRODUCT_POST_API_URL + "product/importPreferredSupplier",
    postObject
  );
};

export const uploadMinimumStockCsvFile = (
  postObject: FormData
): Promise<AxiosResponse> => {
  return axios.post(
    PRODUCT_POST_API_URL + "product/importProductMinimumStock",
    postObject
  );
};
