import React, {FC, useEffect, useState} from 'react';
import UploadSupplierFileInput from './UploadSupplierFileInput';
import {Card} from 'react-bootstrap';
import {FaDownload} from 'react-icons/fa';
import {ISupplier} from '../../../services/apis/api-types/apis.types';
import {getAllSuppliers} from '../../../services/apis/apis';
import Select from 'react-select';
import UploadPreferredSupplierFileInput from './UploadPreferredSupplierFileInput';
import UpLoadMinimumStockFileInput from './UploadMinimumStockFileInput';
import locationOptions from '../../../config/options/locations';

const CSVUpload: FC = () => {
  const [suppliers, setSuppliers] = useState<ISupplier[]>([]);
  const [selectedSupplierId, setSelectedSupplierId] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');

  useEffect(() => {
    const fetchSuppliers = async () => {
      const {data} = await getAllSuppliers();
      setSuppliers(data);
    };
    fetchSuppliers();
  }, []);

  return (
    <>
      <Card className='mt-2 '>
        <Card.Header>Artikel
          <a
            className='float-right'
            style={{color: 'gray'}}
            href={process.env.PUBLIC_URL + '/csv/example_article.csv'}
            download
            title='Download voorbeeld CSV bestand'
          >
            <FaDownload />
          </a>
        </Card.Header>
        <Card.Body>
          <Select className='mb-3'
                  isSearchable
                  placeholder={'Selecteer een leverancier'}
                  options={suppliers}
                  getOptionLabel={(supplier) => supplier.name}
                  getOptionValue={(option) => option.name}
                  onChange={(e) => setSelectedSupplierId(e ? e.supplierId : '')}
                  defaultValue={suppliers.filter(supplier => supplier.supplierId === selectedSupplierId)}
          />
          <strong>Vereiste kolommen: </strong>
          id,artikelNummer,naam,inkoopPrijs,verpakkingsAantal,bestelEenheid,minimumBestelAantal,geldigVanaf
          <UploadSupplierFileInput supplierId={selectedSupplierId} />
        </Card.Body>
      </Card>
      <Card className='mt-3'>
        <Card.Header>
                    <span>
                         Minimale voorraad
                    </span>
          <a
            className='float-right'
            style={{color: 'gray'}}
            href={process.env.PUBLIC_URL + '/csv/example_minimum_stock.csv'}
            download
            title='Download voorbeeld CSV bestand'
          >
            <FaDownload />
          </a>
        </Card.Header>
        <Card.Body>
          <Select className='mb-3'
                  isSearchable
                  placeholder={'Selecteer een locatie'}
                  options={locationOptions}
                  getOptionLabel={(location) => location.label}
                  onChange={(e) => setSelectedLocation(e ? e.value : '')}
                  defaultValue={locationOptions.filter(location => location.value === selectedLocation)}
          />
          <strong>Vereiste kolommen: </strong>
          id, naam, minimumVoorraad
          <UpLoadMinimumStockFileInput selectedLocation={selectedLocation} />
        </Card.Body>
      </Card>

      <Card className='mt-2'>
        <Card.Header>Voorkeursleverancier
          <a
            className='float-right'
            style={{color: 'gray'}}
            href={process.env.PUBLIC_URL + '/csv/example_preferred_suppliers.csv'}
            download
            title='Download voorbeeld CSV bestand'
          >
            <FaDownload />
          </a>
        </Card.Header>
        <Card.Body>
          <strong>Vereiste kolommen: </strong>
          productId, productnaam, leveranciersId, leveranciernaam, landcode
          <UploadPreferredSupplierFileInput />
        </Card.Body>
      </Card>
    </>
  );
};

export default CSVUpload;
